import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import tr_it from "./i18n/it.json";
import tr_en from "./i18n/en.json";

import { format as formatDate, isDate } from "date-fns";

import { enGB, it } from "date-fns/locale";

const locales = { enGB, it };

const getDateFnsLocaleFromLang = (lang = "it"): Locale => {
  let langFixed = lang;
  let temp = lang.split("-");

  if (temp.length > 1) {
    langFixed = temp[0];
  }
  if (langFixed === "en") {
    langFixed = "enGB";
  }
  return locales[langFixed as keyof typeof locales];
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "it",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          if (format === "dayMonthShort")
            return formatDate(value, "d LLL", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          if (format === "hourMinutes")
            return formatDate(value, "p", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          if (format === "shortDate")
            return formatDate(value, "P", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          if (format === "longDate")
            return formatDate(value, "PPPP", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          if (format === "shortDateTime")
            return formatDate(value, "Pp", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          if (format === "dateTime")
            return formatDate(value, "PPP - p", {
              locale: getDateFnsLocaleFromLang(lng),
            });
          //vedi https://codesandbox.io/s/react-date-formatting-in-translations-v27h6?from-embed=&file=/src/translation.json:176-193
          // if (format === 'relative') return formatRelative(value, new Date(), { locale: getDateFnsLocaleFromLang(lng) });
          // if (format === 'ago')l
          //   return formatDistance(value, new Date(), {
          //     locale: getDateFnsLocaleFromLang(lng),
          //     addSuffix: true,
          //   });

          return formatDate(value as number | Date, format as string, {
            locale: getDateFnsLocaleFromLang(lng),
          });
        }
        return value;
      },
    },
    resources: {
      it: {
        translation: tr_it,
      },
      en: {
        translation: tr_en,
      },
    },
    supportedLngs: ["en", "it"],
  });

export default i18n;
