import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BreadcrumbOption {
  title: string;
  current?: boolean;
  to?: string;
}

export interface BreadcrumbProps {
  options: BreadcrumbOption[];
}

export const Breadcrumb = ({ options }: BreadcrumbProps) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs>
      {options.map((el) => (
        <Link
          key={el.title}
          sx={{
            display: "flex",
            alignItems: "center",
            color: el.current ? "" : "inherit",
          }}
          underline={el.to !== undefined ? "hover" : "none"}
          onClick={() => {
            if (el.to) {
              navigate(el.to);
            }
          }}
        >
          <Typography fontSize={"40px"}>{el.title}</Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};
