import styled from "@emotion/styled";
import { Box } from "@mui/material";

/**
 * Header di tutte le pagine sotto il menù
 */
export const Header = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: "20px",
}));

export const FilterWrapper = styled(Box)`
  border: 1px solid lightGray;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const FlexEndContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexVerticalAlign = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileUploaderItemWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 10px;
  margin-bottom: 20px;
`;

export const FileUploaderItemActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
