import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface DialogContextType {
  createDialog: (props: CustomDialogProps) => void;
  closeDialog: () => void;
  createSimpleConfirm: (props: SimpleConfirmProps) => void;
}

interface CustomDialogProps extends DialogProps {
  children: ReactNode;
  onClose?: () => void;
}

interface SimpleConfirmProps {
  title: ReactNode | string;
  message: ReactNode | string;
  onConfirm: () => void;
  onAbort?: () => void;
  confirmTxt?: string;
  cancelTxt?: string;
}

const DialogContext = createContext<DialogContextType>({} as DialogContextType);

export function DialogProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [dialogs, setDialogs] = useState<CustomDialogProps[]>([]);

  const { t } = useTranslation();

  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  const createDialog = (option: CustomDialogProps) => {
    const dialog: CustomDialogProps = {
      onBackdropClick: closeDialog,
      ...option,
      open: true,
    };
    const updatedDialogs = [];
    dialogs.forEach((d) => updatedDialogs.push(Object.assign({}, d)));
    updatedDialogs.push(dialog);
    setDialogs(updatedDialogs);
  };

  /**
   * Crea un dialog basico da utilizzare in situazioni standard
   * @param title
   * @param message
   * @param onConfirm
   * @param onAbort
   * @param confirmTxt
   * @param cancelTxt
   */
  const createSimpleConfirm = ({
    title,
    message,
    onConfirm,
    onAbort,
    confirmTxt = t("actions.confirm"),
    cancelTxt = t("actions.cancel"),
  }: SimpleConfirmProps) => {
    const abort = () => {
      if (onAbort) {
        onAbort();
      }
      closeDialog();
    };

    const confirm = () => {
      onConfirm();
      closeDialog();
    };

    createDialog({
      open: true,
      maxWidth: "sm",
      PaperProps: { sx: { position: "fixed", top: 50, m: 0 } },
      children: (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions
            sx={{ justifyContent: "flex-end", padding: "8px 24px" }}
          >
            <Button
              color="secondary"
              variant="outlined"
              onClick={abort}
              autoFocus
            >
              {cancelTxt}
            </Button>
            <Button variant="contained" color="primary" onClick={confirm}>
              {confirmTxt}
            </Button>
          </DialogActions>
        </>
      ),
    });
  };

  const cachedValue = useMemo(
    () => ({
      createDialog,
      closeDialog,
      createSimpleConfirm,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dialogs]
  );
  return (
    <DialogContext.Provider value={cachedValue}>
      {children}
      {dialogs.map((dialog, i) => {
        return <DialogContainer key={i} {...dialog} />;
      })}
    </DialogContext.Provider>
  );
}

function DialogContainer({ children, onClose, ...rest }: CustomDialogProps) {
  return (
    <Dialog onClose={onClose} {...rest}>
      {children}
    </Dialog>
  );
}

export const useDialog = () => useContext(DialogContext);
