import { useState } from "react";
import { useMessage } from "../context/useMessage";

interface FileUploaderProps {
  count?: number;
}

export const useFileUploader = ({ count }: FileUploaderProps) => {
  const [files, setFiles] = useState<File[]>([]);

  const { error } = useMessage();

  const addFile = (file: File) => {
    if (count && count < files.length + 1) {
      error({ title: "Errore", message: `Massimo ${count} file alla volta` });
    } else {
      setFiles((prev) => [...prev, file]);
    }
  };

  const addFiles = (elements: File[]) => {
    if (count && count < files.length + elements.length) {
      error({ title: "Errore", message: `Massimo ${count} file alla volta` });
    } else {
      setFiles((prev) => [...prev, ...elements]);
    }
  };

  const removeFile = (name: string) => {
    setFiles(files.filter((el) => el.name !== name));
  };

  return { files, addFile, addFiles, removeFile };
};
