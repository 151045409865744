import { LoadingButton } from "@mui/lab";
import { Container, Stack } from "@mui/material";
import { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../context/useApi";
import { useMessage } from "../../context/useMessage";
import { FormError, useBaseForm } from "../../hooks/useBaseForm";
import { ROUTES } from "../../utils/const/routes";
import { getErrorResponse } from "../../utils/helpers/errors";
import { getPasswordErrorLabel } from "../../utils/helpers/regex";
import { Password } from "../common/base";
import { Breadcrumb } from "../common/Breadcrumb";
import { Header } from "../common/Common.styles";
import { PasswordCriteria } from "./PasswordCriteria";

export const ChangePassword = (): ReactElement => {
  const { t } = useTranslation();

  const { userApi } = useApi();

  const { error, success } = useMessage();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validate = useCallback((form: any) => {
    const errors: FormError[] = [];
    if (form.newPassword) {
      const err = getPasswordErrorLabel(form.newPassword);
      if (err) {
        errors.push({ field: "newPassword", code: err });
      }
    }

    if (form.newPassword && form.confirmPassword) {
      if (form.newPassword !== form.confirmPassword) {
        errors.push({ field: "confirmPassword", code: "passwordMustBeEquals" });
      }
    }
    return errors;
  }, []);

  const changePassword = () => {
    setLoading(true);
    userApi
      .changePassword({
        newPassword: form.newPassword,
        oldPassword: form.oldPassword,
      })
      .then((resp) => {
        if (resp.status === 200) {
          navigate(ROUTES.users);
          success({ message: t("info.success") });
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { form, bindField, errors, submit } = useBaseForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: changePassword,
    onSubmitValidatior: validate,
  });

  return (
    <>
      <Container maxWidth="xs">
        <Header style={{ justifyContent: "center" }}>
          <Breadcrumb
            options={[{ title: t("actions.changePassword"), current: true }]}
          />
        </Header>
        <PasswordCriteria />
        <Stack
          direction={"column"}
          spacing={4}
          alignItems={"center"}
          sx={{ marginTop: "20px" }}
        >
          <Password
            label={t("access.oldPassword")}
            fullWidth
            {...bindField("oldPassword")}
          />

          <Password
            label={t("access.newPassword")}
            fullWidth
            {...bindField("newPassword")}
          />

          <Password
            label={t("access.confirmPassword")}
            fullWidth
            {...bindField("confirmPassword")}
          />

          <LoadingButton
            loading={loading}
            disabled={
              form.oldPassword &&
              form.newPassword &&
              form.confirmPassword &&
              errors.length < 1
                ? false
                : true
            }
            variant="contained"
            onClick={submit}
          >
            {t("actions.changePassword")}
          </LoadingButton>
        </Stack>
      </Container>
    </>
  );
};
