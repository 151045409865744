import { Button, IconButton, TextField, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { ROUTES } from "../../../utils/const/routes";
import { FileUploader } from "../../fileUpload/FileUploader";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { useFileUploader } from "../../../hooks/useFileUploader";
import { useDialog } from "../../../context/useDialog";
import { useTranslation } from "react-i18next";
import {
  FileUploaderItemActionWrapper,
  FileUploaderItemWrapper,
  Header,
} from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import { useTemplateDetail } from "../../../hooks/useTemplateDetail";
import { ICMResponse } from "../../../generated/axios";

const defaultValues: Omit<ICMResponse, "id"> = {
  cm: "",
  version: "",
};

export const TemplateDetail = (): ReactElement => {
  const { id } = useParams();

  const { detail, errors, save } = useTemplateDetail(id);

  const { t } = useTranslation();

  const { files, addFiles, removeFile } = useFileUploader({ count: 1 });

  const { createSimpleConfirm } = useDialog();

  const { bindField, submit, setErrors } = useBaseForm({
    defaultValues: defaultValues,
    onSubmit: (el: any) => {
      createSimpleConfirm({
        title: t("info.warning"),
        message: t("info.sureToUpdateThisVersion"),
        onConfirm: () => {
          el.cm = (el.cm as string).toUpperCase();
          save(el, files[0]);
        },
      });
    },
    values: detail,
  });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const getBreadcrumbName = () => {
    return t("actions.new");
  };

  return (
    <>
      <Header>
        <Breadcrumb
          options={[
            { title: t("template.title"), to: ROUTES.templates },
            { title: getBreadcrumbName(), current: true },
          ]}
        />
      </Header>
      <FileUploader onUpload={addFiles} count={1} />
      {files.map((el, index) => (
        <FileUploaderItemWrapper key={index}>
          <Typography>{el.name}</Typography>
          <TextField
            label={t("template.id")}
            {...bindField("cm")}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <TextField
            label={t("template.version")}
            {...bindField("version")}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <FileUploaderItemActionWrapper>
            <Button onClick={submit}>
              <UploadIcon />
              {t("actions.upload")}
            </Button>
            <IconButton color="primary" onClick={() => removeFile(el.name)}>
              <CloseIcon />
            </IconButton>
          </FileUploaderItemActionWrapper>
        </FileUploaderItemWrapper>
      ))}
    </>
  );
};
