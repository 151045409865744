import { ReactElement } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
//Icons
import PersonIcon from "@mui/icons-material/Person";
import DataObjectIcon from "@mui/icons-material/DataObject";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ROUTES } from "../utils/const/routes";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/useAuth";

interface Props {
  open: boolean;
}

export const Menu = ({ open }: Props): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { loggedUser } = useAuth();

  return (
    <>
      <List>
        {loggedUser?.permissions?.includes("USER") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.users);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("user.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {loggedUser?.permissions?.includes("MANUFACTURER") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.manufacturers);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <EngineeringIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("productor.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        {loggedUser?.permissions?.includes("ASKOLL_KIT") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.kits);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <CalendarViewMonthIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("kit.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {loggedUser?.permissions?.includes("FIRMWARE") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.firmwares);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DataObjectIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("firmware.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {loggedUser?.permissions?.includes("TEMPLATE") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.templates);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DocumentScannerIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("template.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        {loggedUser?.permissions?.includes("APP_VERSION") && (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => {
                navigate(ROUTES.releases);
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <CloudUploadIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={t("release.title")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </>
  );
};
