import { ReactElement } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ManufacturerFilters } from "./ManufacturerFilters";
import { Button, Chip, Divider, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "../../../utils/const/routes";
import { useTranslation } from "react-i18next";
import { Header } from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import {
  IAskollKitResponse,
  IManufacturerResponse,
} from "../../../generated/axios";
import theme from "../../../theme";
import { PAGE_SIZE_LIST } from "../../../utils/const/conf";
import { SearchFnReturn, useBaseList } from "../../../hooks/useBaseList";
import { getErrorResponse } from "../../../utils/helpers/errors";
import { useMessage } from "../../../context/useMessage";
import { useApi } from "../../../context/useApi";

export const ManufacturerList = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { error } = useMessage();

  const { manufacturerApi } = useApi();

  const searchFn = async (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ): Promise<SearchFnReturn<IManufacturerResponse>> => {
    const resp = await manufacturerApi.searchManufacturers({
      filters: filters,
      start: start,
      size: size,
      sort: sort,
    });
    if (resp.status === 200) {
      return { total: resp.data.total ?? 0, list: resp.data.data ?? [] };
    } else {
      const err = t(`errors.${getErrorResponse(resp).code}`);
      error({ title: err });
      throw Error(err);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${ROUTES.manufacturer}/${params.row.id}`);
  };

  const {
    list,
    loading,
    page,
    pageSize,
    total,
    sortModel,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useBaseList<IManufacturerResponse>({ searchFn });

  const columns: GridColDef[] = [
    { field: "name", headerName: t("productor.name"), flex: 1 },
    {
      field: "askollKits",
      headerName: t("productor.kit"),
      flex: 4,
      sortable: false,
      valueGetter: (params: any) => params.row.askollKits,
      renderCell: (params: GridRenderCellParams<IAskollKitResponse[]>) => (
        <>
          {params.value?.map((el) => (
            <Chip
              key={el.id}
              label={el.name}
              sx={{
                marginRight: "10px",
                border: `1px solid ${theme.palette.primary.light}`,
                ":hover": { border: `1px solid ${theme.palette.primary.main}` },
              }}
              onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                ev.preventDefault();
                ev.stopPropagation();
                navigate(`${ROUTES.kit}/${el.id}`);
              }}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <>
      <Header>
        <Stack
          spacing={3}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb
            options={[{ title: t("productor.title"), current: true }]}
          />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.manufacturer);
            }}
          >
            {t("actions.new")}
          </Button>
        </Stack>
      </Header>
      <ManufacturerFilters search={onFilterChange} loading={loading} />
      <DataGrid
        sx={{ height: "calc(100vh - 300px)" }}
        loading={loading}
        disableColumnMenu
        onRowClick={handleRowClick}
        columns={columns}
        rows={list}
        rowCount={total}
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={PAGE_SIZE_LIST}
        pagination
        paginationMode="server"
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={onSortModelChanged}
      />
    </>
  );
};
