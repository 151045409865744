import { ReactElement } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Login } from "../components/access/Login";
import { FirmwareList } from "../components/firmware/list/FirmwareList";
import { UserDetail } from "../components/user/detail/UserDetail";
import { UserList } from "../components/user/list/UserList";
import { DialogProvider } from "../context/useDialog";
import { MessageProvider } from "../context/useMessage";
import { BaseLayout } from "../layout";
import { ROUTES } from "../utils/const/routes";
import { ManufacturerDetail } from "../components/manufacturer/detail/ManufacturerDetail";
import { ManufacturerList } from "../components/manufacturer/list/ManufacturerList";
import { KitList } from "../components/kit/list/KitList";
import { KitDetail } from "../components/kit/detail/KitDetail";
import { TemplateList } from "../components/template/list/TemplateList";
import { FirmwareDetail } from "../components/firmware/detail/FirmwareDetail";
import { ReleaseList } from "../components/release/list/ReleaseList";
import { ReleaseDetail } from "../components/release/detail/ReleaseDetail";
import { AuthenticationProvider, useAuth } from "../context/useAuth";
import { ApiContextProvider } from "../context/useApi";
import { TemplateDetail } from "../components/template/detail/TemplateDetail";
import { ChangePassword } from "../components/access/ChangePassword";
import { SendPasswordRecovery } from "../components/access/SendPasswordRecovery";
import { PasswordRecovery } from "../components/access/PasswordRecovery";
import { ProtectedRoute } from "./PermissionRoute";

const AppRoutes = (): ReactElement => {
  /**
   * protegge le rotte sottostanti
   * @returns la rotta se autenticato
   */
  const RequireAuth = () => {
    const { token } = useAuth();
    if (token === undefined) {
      return <Navigate to={ROUTES.login} />;
    } else {
      return <Outlet />;
    }
  };

  return (
    <BrowserRouter>
      <ApiContextProvider>
        <MessageProvider>
          <AuthenticationProvider>
            <DialogProvider>
              <Routes>
                <Route
                  path={ROUTES.home}
                  element={<Navigate to={ROUTES.users} />}
                />
                <Route path={ROUTES.login} element={<Login />} />
                <Route
                  path={ROUTES.sendPasswordRecovery}
                  element={<SendPasswordRecovery />}
                />
                <Route
                  path={ROUTES.passwordRecovery}
                  element={<PasswordRecovery />}
                />
                <Route
                  path={ROUTES.passwordRegistration}
                  element={<PasswordRecovery isNew />}
                />
                <Route element={<RequireAuth />}>
                  <Route element={<BaseLayout />}>
                    <Route
                      path={ROUTES.changePassword}
                      element={<ChangePassword />}
                    />
                    <Route
                      path={ROUTES.users}
                      element={
                        <ProtectedRoute permissions={["USER"]}>
                          <UserList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={`${ROUTES.user}/:id`}
                      element={
                        <ProtectedRoute permissions={["USER"]}>
                          <UserDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.user}
                      element={
                        <ProtectedRoute permissions={["USER"]}>
                          <UserDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.firmwares}
                      element={
                        <ProtectedRoute permissions={["FIRMWARE"]}>
                          <FirmwareList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.firmware}
                      element={
                        <ProtectedRoute permissions={["FIRMWARE"]}>
                          <FirmwareDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.templates}
                      element={
                        <ProtectedRoute permissions={["TEMPLATE"]}>
                          <TemplateList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.template}
                      element={
                        <ProtectedRoute permissions={["TEMPLATE"]}>
                          <TemplateDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.manufacturers}
                      element={
                        <ProtectedRoute permissions={["MANUFACTURER"]}>
                          <ManufacturerList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.manufacturer}
                      element={
                        <ProtectedRoute permissions={["MANUFACTURER"]}>
                          <ManufacturerDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={`${ROUTES.manufacturer}/:id`}
                      element={
                        <ProtectedRoute permissions={["MANUFACTURER"]}>
                          <ManufacturerDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.kits}
                      element={
                        <ProtectedRoute permissions={["ASKOLL_KIT"]}>
                          <KitList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.kit}
                      element={
                        <ProtectedRoute permissions={["ASKOLL_KIT"]}>
                          <KitDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={`${ROUTES.kit}/:id`}
                      element={
                        <ProtectedRoute permissions={["ASKOLL_KIT"]}>
                          <KitDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.releases}
                      element={
                        <ProtectedRoute permissions={["APP_VERSION"]}>
                          <ReleaseList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path={ROUTES.release}
                      element={
                        <ProtectedRoute permissions={["APP_VERSION"]}>
                          <ReleaseDetail />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Route>
              </Routes>
            </DialogProvider>
          </AuthenticationProvider>
        </MessageProvider>
      </ApiContextProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
