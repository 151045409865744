import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IUserResponse } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";
import { getErrorResponse } from "../utils/helpers/errors";
import { useApi } from "./useApi";
import { useMessage } from "./useMessage";

interface AuthenticationContextType {
  token?: string;
  login: (email: string, password: string) => void;
  logout: () => void;
  loggedUser: IUserResponse | undefined;
}

const AuthenticationContext = createContext<AuthenticationContextType>(
  {} as AuthenticationContextType
);

export const AUTH_TOKEN = "ask-token";

export function AuthenticationProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [loggedUser, setLoggedUser] = useState<IUserResponse>();

  const navigate = useNavigate();

  const { accessApi, userApi } = useApi();

  const { t } = useTranslation();

  const { error } = useMessage();

  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    userApi.getLogged().then((resp) => {
      if (resp.status === 200) {
        setLoggedUser(resp.data);
      } else {
        setLoggedUser(undefined);
        error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        logout();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userApi]);

  const login = useCallback((email: string, password: string) => {
    accessApi
      .login({ email: email, password: password })
      .then((resp) => {
        if (resp.status === 200) {
          //@ts-ignore
          setCookie(AUTH_TOKEN, resp.data.accessToken, {
            secure: true,
          });
          navigate(ROUTES.home);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      })
      .catch(() => {
        error({ title: t(`errors.BAD_CREDENTIALS`) });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    accessApi.logout().then((resp) => {
      if (resp.status === 200) {
        setLoggedUser(undefined);
        removeCookie(AUTH_TOKEN, { secure: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cachedValue: AuthenticationContextType = useMemo(
    () => ({
      login: login,
      logout: logout,
      token: cookies[AUTH_TOKEN],
      loggedUser,
    }),
    [cookies, loggedUser, login, logout]
  );

  return (
    <AuthenticationContext.Provider value={cachedValue}>
      {children}
    </AuthenticationContext.Provider>
  );
}

//hook per utilizzo webSocket
export const useAuth = () => useContext(AuthenticationContext);
