import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../context/useApi";
import { ISendInviteRequestEmailTextLangEnum } from "../../../generated/axios";
import { Lang } from "../../common/base";

import SendIcon from "@mui/icons-material/Send";
import { useMessage } from "../../../context/useMessage";
import { getErrorResponse } from "../../../utils/helpers/errors";

interface SendInviteDialogProps {
  userId: string;
  closeFn: () => void;
  confirmFn: () => void;
}

export const SendInviteDialog = ({
  userId,
  closeFn,
  confirmFn,
}: SendInviteDialogProps): ReactElement => {
  const { t } = useTranslation();

  const { userApi } = useApi();

  const [lang, setLang] = useState<ISendInviteRequestEmailTextLangEnum>("IT");

  const [loading, setLoading] = useState(false);

  const { error } = useMessage();

  const sendInvite = () => {
    setLoading(true);
    userApi
      .sendInvite(userId, { emailTextLang: lang })
      .then((resp) => {
        if (resp.status === 200) {
          confirmFn();
          closeFn();
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h3" color={"secondary"}>
          {t("info.sendAgain")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={"column"}
          spacing={4}
          alignItems="center"
          sx={{ mb: "20px" }}
        >
          <Typography color="secondary" padding={2}>
            {t("info.sendAgainText")}
          </Typography>
          <Lang
            sx={{ width: "60%" }}
            size="small"
            label={t("user.lang")}
            value={lang}
            onChange={(e) => {
              setLang(e.target.value as ISendInviteRequestEmailTextLangEnum);
            }}
            useServerValue
          ></Lang>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={closeFn}>
          {t("actions.cancel")}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={sendInvite}
          variant="contained"
          endIcon={<SendIcon />}
          loadingPosition="end"
        >
          {t("actions.sendInvite")}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
