import { Alert, AlertColor, AlertProps, AlertTitle, Slide, Snackbar } from '@mui/material';
import _ from 'lodash';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface MessageProps {
  title?: string;
  message?: string;
  onCloseFn?: () => void;
  timeOut?: number;
}

interface CustomAlertProps extends AlertProps {
  timeOut?: number;
  onCloseFn?: () => void;
}

interface MessageContextType {
  info: (props: MessageProps) => void;
  error: (props: MessageProps) => void;
  warning: (props: MessageProps) => void;
  success: (props: MessageProps) => void;
  custom: (props: CustomAlertProps, timeOut?: number) => void;
}

const MessageContext = createContext<MessageContextType>({} as MessageContextType);

export function MessageProvider({ children }: { children: ReactNode }): JSX.Element {
  const [message, setMessage] = useState<CustomAlertProps>();

  const [open, setOpen] = useState(false);

  const handleClose = (callBack?: () => void) => {
    setMessage(undefined);
    setOpen(false);
    if (callBack) {
      callBack();
    }
  };

  const info = ({ title, message, onCloseFn, timeOut }: MessageProps) => {
    standardMessage({ title, message, onCloseFn, timeOut }, 'info');
  };

  const error = ({ title, message, onCloseFn, timeOut }: MessageProps) => {
    standardMessage({ title, message, onCloseFn, timeOut }, 'error');
  };

  const success = ({ title, message, onCloseFn, timeOut }: MessageProps) => {
    standardMessage({ title, message, onCloseFn, timeOut }, 'success');
  };

  const warning = ({ title, message, onCloseFn, timeOut }: MessageProps) => {
    standardMessage({ title, message, onCloseFn, timeOut }, 'warning');
  };

  const standardMessage = (props: MessageProps, severity: AlertColor, timeOut: number = 5000) => {
    setMessage({
      severity: severity,
      title: props.title,
      children: props.message,
      onCloseFn: props.onCloseFn,
      timeOut: timeOut,
    });
    setOpen(true);
  };

  const custom = ({ timeOut = 6000, ...rest }: CustomAlertProps) => {
    setMessage({ timeOut, ...rest });
    setOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedValue = useMemo(() => ({ info, error, success, warning, custom }), [message]);

  return (
    <MessageContext.Provider value={cachedValue}>
      {children}
      {message && (
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <Snackbar
            open={open}
            autoHideDuration={message.timeOut}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClose={() => {
              handleClose(message.onCloseFn);
            }}
          >
            <Alert
              {..._.omit(message, ['timeOut', 'onCloseFn'])}
              sx={{ width: '100%' }}
              onClose={() => {
                handleClose(message.onCloseFn);
              }}
            >
              {message.title && <AlertTitle>{message.title}</AlertTitle>}
              {message.children}
            </Alert>
          </Snackbar>
        </Slide>
      )}
    </MessageContext.Provider>
  );
}

export const useMessage = () => useContext(MessageContext);
