import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApi } from "../context/useApi";
import { useMessage } from "../context/useMessage";
import { ISWResponse } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";
import { getErrorResponse } from "../utils/helpers/errors";
import { FormError } from "./useBaseForm";

export const useFirmwareDetail = (id?: string) => {
  const [loading, setLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const [errors, setErrors] = useState<FormError[]>([]);

  const navigate = useNavigate();

  const [detail, setDetail] = useState<ISWResponse>();

  const { error, success } = useMessage();

  const { firmwareApi } = useApi();

  const { t } = useTranslation();

  const get = useCallback(
    (id: string) => {
      setLoading(true);
      firmwareApi
        .getSw(id)
        .then((resp) => {
          if (resp.status === 200) {
            setDetail(resp.data);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firmwareApi]
  );

  useEffect(() => {
    if (id) {
      get(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, get]);

  const save = (el: any, file: any) => {
    setSaveLoading(true);
    firmwareApi
      .uploadSw({ ...el }, file)
      .then((resp) => {
        if (resp.status === 200) {
          setDetail(resp.data);
          success({ message: t("info.saved") });
          navigate(ROUTES.firmwares);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          setErrors(getErrorResponse(resp).errors ?? []);
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleCancel = () => {
    if (!id) {
      navigate(ROUTES.kits);
    } else {
      get(id);
    }
  };

  return { detail, loading, saveLoading, errors, save, handleCancel };
};
