import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../context/useApi";
import { useMessage } from "../../context/useMessage";
import { FormError, useBaseForm } from "../../hooks/useBaseForm";
import i18n from "../../i18n";
import theme from "../../theme";
import { ROUTES } from "../../utils/const/routes";
import { getErrorResponse } from "../../utils/helpers/errors";
import { getPasswordErrorLabel } from "../../utils/helpers/regex";
import { Lang, Password } from "../common/base";
import { PasswordCriteria } from "./PasswordCriteria";
import { RegistrationCompleted } from "./RegistrationCompleted";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 3px solid ${theme.palette.primary.main};
  padding: 25px;
`;

const ErrorForm = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 3px solid ${theme.palette.error.main};
  padding: 100px;
`;

interface Props {
  isNew?: boolean;
}

export const PasswordRecovery = ({ isNew }: Props): ReactElement => {
  const { t } = useTranslation();

  const [params] = useSearchParams();

  const app = useMemo(() => {
    return params.has("app_user");
  }, [params]);

  const { userApi } = useApi();

  const [loading, setLoading] = useState(true);

  const [token, setToken] = useState<string>();

  const [tokenError, setTokenError] = useState<string>();

  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  const { error } = useMessage();

  const validate = useCallback((form: any) => {
    const errors: FormError[] = [];
    if (form.newPassword) {
      const err = getPasswordErrorLabel(form.newPassword);
      if (err) {
        errors.push({ field: "newPassword", code: err });
      }
    }

    if (form.newPassword && form.confirmPassword) {
      if (form.newPassword !== form.confirmPassword) {
        errors.push({ field: "confirmPassword", code: "passwordMustBeEquals" });
      }
    }
    return errors;
  }, []);

  const changePassword = () => {
    setErrors([]);
    userApi
      .resetPasswordSubmit(token as string, {
        newPassword: form.newPassword,
      })
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data && !app) {
            navigate(ROUTES.login);
          } else {
            setCompleted(true);
          }
        } else {
          setErrors(
            getErrorResponse(resp).errors?.map((el) => ({
              field: el.field,
              message: el.code,
            })) ?? []
          );
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      });
  };

  const { form, bindField, setErrors, errors, submit } = useBaseForm({
    defaultValues: { newPassword: "", confirmPassword: "" },
    onSubmit: changePassword,
    onSubmitValidatior: validate,
  });

  const validateToken = useCallback((token: string) => {
    userApi
      .validatePasswordRecoveryToken(token)
      .then((resp) => {
        if (resp.status === 200) {
          setToken(token);
        } else {
          setTokenError(t(`errors.${getErrorResponse(resp).code}`));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params && params.getAll("token").length > 0) {
      validateToken(params.getAll("token")[0]);
    } else {
      setLoading(false);
      setTokenError(t(`errors.INVALID_TOKEN`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, validateToken]);

  if (completed) {
    return <RegistrationCompleted />;
  }

  if (loading)
    return (
      <Container maxWidth="md">
        <Wrapper>
          <img
            src={window.location.origin + "/img/askoll_ultra_logo.png"}
            alt="askoll_ultra_logo"
            style={{ width: "50%", marginTop: "30px", marginBottom: "30px" }}
          />
          <CircularProgress />
        </Wrapper>
      </Container>
    );

  if (tokenError) {
    return (
      <Container maxWidth="md">
        <Wrapper>
          <img
            src={window.location.origin + "/img/askoll_ultra_logo.png"}
            alt="askoll_ultra_logo"
            style={{ width: "50%", marginTop: "30px", marginBottom: "30px" }}
          />
          <ErrorForm>
            <Typography fontWeight={600} fontSize={"50px"} color="secondary">
              {tokenError}
            </Typography>
          </ErrorForm>
        </Wrapper>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="md">
        <Wrapper>
          <img
            src={window.location.origin + "/img/askoll_ultra_logo.png"}
            alt="askoll_ultra_logo"
            style={{ width: "50%", marginTop: "30px", marginBottom: "30px" }}
          />
          <FormWrapper>
            <Typography fontWeight={600} fontSize={"50px"} color="secondary">
              {isNew
                ? t("access.passwordRegistrationForm.title")
                : t("access.passwordRecoveryForm.title")}
            </Typography>

            <Typography
              fontWeight={600}
              fontSize={"20px"}
              color="secondary"
              sx={{ mb: "10px" }}
            >
              {isNew
                ? t("access.passwordRegistrationForm.subtitle")
                : t("access.passwordRecoveryForm.subtitle")}
            </Typography>

            <PasswordCriteria />

            <Password
              sx={{ mb: "40px", mt: "10px", width: "500px" }}
              label={t("access.password")}
              fullWidth
              {...bindField("newPassword")}
            />
            <Password
              sx={{ mb: "20px", width: "500px" }}
              label={t("access.confirmPassword")}
              fullWidth
              {...bindField("confirmPassword")}
            />

            <Lang
              label={t("access.lang")}
              sx={{ mb: "40px", width: "300px" }}
              size="small"
              fullWidth
              value={i18n.language}
              onChange={(e) => {
                i18n.changeLanguage(e.target.value);
              }}
            />

            <Stack
              justifyContent="flex-end"
              sx={{ width: "100%" }}
              flexDirection="row"
            >
              <Button
                variant="contained"
                size="large"
                disabled={
                  form.newPassword && form.confirmPassword && errors.length < 1
                    ? false
                    : true
                }
                onClick={submit}
              >
                {t("actions.confirm")}
              </Button>
            </Stack>
          </FormWrapper>
        </Wrapper>
      </Container>
    );
  }
};
