import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../context/useApi";
import { useMessage } from "../../context/useMessage";
import {
  AUTOCOMPLETE_MIN_CHARACTERS_FOR_SEARCH,
  AUTOCOMPLETE_SEARCH_LIMIT,
} from "../../utils/const/conf";
import { getErrorResponse } from "../../utils/helpers/errors";

export interface FirmwareAutocompleteProps {
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  label: string;
  helperText?: string;
}

export const FirmwareAutocomplete = ({
  placeholder,
  onChange,
  value,
  label,
  helperText,
}: FirmwareAutocompleteProps): ReactElement => {
  const [options, setOptions] = useState<string[]>([]);

  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { error } = useMessage();

  const { firmwareApi } = useApi();

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length >= AUTOCOMPLETE_MIN_CHARACTERS_FOR_SEARCH) {
        setLoading(true);
        firmwareApi
          .searchDistinctSws({
            filters: {
              //@ts-ignore
              sw: inputValue,
            },
            start: 0,
            size: AUTOCOMPLETE_SEARCH_LIMIT,
          })
          .then((resp) => {
            if (resp.status === 200 && resp.data) {
              setOptions(resp.data.data?.map((el) => el.sw as string) ?? []);
            } else {
              error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      // Send Axios request here
    }, 200);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmwareApi, inputValue]);

  const renderTagHandler = (
    val: string[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    return val.map((item, index: number) => (
      <Chip
        key={index}
        label={item}
        onDelete={getTagProps({ index }).onDelete}
        sx={{ marginRight: "5px" }}
      />
    ));
  };

  const onChangeHandler = (_e: any, val: string | null) => {
    onChange(val as string);
  };

  const optionLabelHandler = (option: string) => {
    return option;
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={inputValue}
      autoSelect
      fullWidth
      filterOptions={(x) => x}
      loading={loading}
      onInputChange={handleInputChange}
      renderTags={renderTagHandler}
      onChange={onChangeHandler}
      getOptionLabel={optionLabelHandler}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          label={label}
          helperText={helperText}
          error={helperText !== undefined}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...inputProps,
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};
