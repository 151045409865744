import { ReactElement } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { KitFilters } from "./KitFilters";
import { Button, Divider, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "../../../utils/const/routes";
import { useTranslation } from "react-i18next";
import { Header } from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import { PAGE_SIZE_LIST } from "../../../utils/const/conf";
import { useApi } from "../../../context/useApi";
import { SearchFnReturn, useBaseList } from "../../../hooks/useBaseList";
import { IAskollKitResponse } from "../../../generated/axios";
import { getErrorResponse } from "../../../utils/helpers/errors";
import { useMessage } from "../../../context/useMessage";

export const KitList = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { kitApi } = useApi();

  const { error } = useMessage();

  const searchFn = async (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ): Promise<SearchFnReturn<IAskollKitResponse>> => {
    const resp = await kitApi.searchAskollKit({
      filters: filters,
      start: start,
      size: size,
      sort: sort,
    });
    if (resp.status === 200) {
      return { total: resp.data.total ?? 0, list: resp.data.data ?? [] };
    } else {
      const err = t(`errors.${getErrorResponse(resp).code}`);
      error({ title: err });
      throw Error(err);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${ROUTES.kit}/${params.row.id}`);
  };

  const {
    list,
    loading,
    page,
    pageSize,
    total,
    sortModel,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useBaseList<IAskollKitResponse>({ searchFn });

  const columns: GridColDef[] = [
    { field: "name", headerName: t("kit.name"), flex: 2 },
    { field: "cmReference", headerName: t("kit.template"), flex: 1 },
    { field: "askollUnitProductId", headerName: t("kit.product"), flex: 1 },
    { field: "swReference", headerName: t("kit.firmware"), flex: 1 },
    {
      field: "hasAskollBattery",
      headerName: t("kit.includedBattery"),
      flex: 1,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.hasAskollBattery ? "SI" : "NO",
    },
  ];

  return (
    <>
      <Header>
        <Stack
          spacing={3}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb options={[{ title: t("kit.title"), current: true }]} />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.kit);
            }}
          >
            {t("actions.new")}
          </Button>
        </Stack>
      </Header>
      <KitFilters search={onFilterChange} loading={loading} />
      <DataGrid
        sx={{ height: "calc(100vh - 350px)" }}
        loading={loading}
        disableColumnMenu
        onRowClick={handleRowClick}
        columns={columns}
        rows={list}
        rowCount={total}
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={PAGE_SIZE_LIST}
        pagination
        paginationMode="server"
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={onSortModelChanged}
      />
    </>
  );
};
