import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SendInviteDialog } from "../components/user/detail/SendInviteDialog";
import { useApi } from "../context/useApi";
import { useDialog } from "../context/useDialog";
import { useMessage } from "../context/useMessage";
import { IUserResponse } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";
import { getErrorResponse } from "../utils/helpers/errors";
import { FormError } from "./useBaseForm";

export const useUserDetail = (id?: string) => {
  const [loading, setLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const [errors, setErrors] = useState<FormError[]>([]);

  const { createSimpleConfirm, createDialog, closeDialog } = useDialog();

  const navigate = useNavigate();

  const [detail, setDetail] = useState<IUserResponse>();

  const { error, success } = useMessage();

  const { userApi } = useApi();

  const { t } = useTranslation();

  const get = useCallback(
    (id: string) => {
      setLoading(true);
      userApi
        .getUser(id)
        .then((resp) => {
          if (resp.status === 200) {
            setDetail(resp.data);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userApi]
  );

  useEffect(() => {
    if (id) {
      get(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, get]);

  const save = (el: any) => {
    if (el.id) {
      setSaveLoading(true);
      let manufacturerId = el.manufacturer ? el.manufacturer.id : undefined;
      userApi
        .updateUser(el.id, { ...el, manufacturerId: manufacturerId })
        .then((resp) => {
          if (resp.status === 200) {
            setDetail(resp.data);
            success({ message: t("info.saved") });
            setErrors([]);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            setErrors(getErrorResponse(resp).errors ?? []);
          }
        })
        .finally(() => {
          setSaveLoading(false);
        });
    } else {
      setSaveLoading(true);
      let manufacturerId = el.manufacturer ? el.manufacturer.id : undefined;
      userApi
        .newUser({ ...el, manufacturerId: manufacturerId })
        .then((resp) => {
          if (resp.status === 200) {
            success({ message: t("info.saved") });
            setErrors([]);
            navigate(resp.data.id as string);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            setErrors(getErrorResponse(resp).errors ?? []);
          }
        })
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };

  const handleCancel = () => {
    if (!id) {
      navigate(ROUTES.users);
    } else {
      get(id);
    }
  };

  const handleDelete = () => {
    if (id) {
      createSimpleConfirm({
        message: t("info.sureToDeleteUser"),
        onConfirm: () => {
          userApi.deleteUser(id).then((resp) => {
            if (resp.status === 200) {
              closeDialog();
              success({ message: t("info.deleted") });
              navigate(ROUTES.users);
            } else {
              error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            }
          });
        },
        title: t("info.warning"),
      });
    }
  };

  const sendInvite = () => {
    if (detail?.id) {
      createDialog({
        open: true,
        maxWidth: "sm",
        fullWidth: true,
        children: (
          <SendInviteDialog
            userId={detail.id}
            closeFn={closeDialog}
            confirmFn={() => {
              navigate(ROUTES.users);
            }}
          />
        ),
      });
    }
  };

  return {
    detail,
    loading,
    saveLoading,
    errors,
    save,
    handleCancel,
    handleDelete,
    sendInvite,
  };
};
