import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApi } from "../context/useApi";
import { useMessage } from "../context/useMessage";
import { IDiagnosticAppVersionResponse } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";
import { getErrorResponse } from "../utils/helpers/errors";
import { FormError } from "./useBaseForm";

export const useReleaseDetail = (id?: string) => {
  const [saveLoading, setSaveLoading] = useState(false);

  const [errors, setErrors] = useState<FormError[]>([]);

  const navigate = useNavigate();

  const [detail, setDetail] = useState<IDiagnosticAppVersionResponse>();

  const { error, success } = useMessage();

  const { appVersionApi } = useApi();

  const { t } = useTranslation();

  const save = (el: any, file: any) => {
    setSaveLoading(true);
    appVersionApi
      .uploadAppVersion({ ...el }, file)
      .then((resp) => {
        if (resp.status === 200) {
          setDetail(resp.data);
          success({ message: t("info.saved") });
          navigate(ROUTES.releases);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          setErrors(getErrorResponse(resp).errors ?? []);
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleCancel = () => {
    if (!id) {
      navigate(ROUTES.releases);
    }
  };

  return { detail, saveLoading, errors, save, handleCancel };
};
