import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export interface FormValue {
  field: string;
  value: any;
}

//FORM FIELDS ERRORS
export interface FormError {
  field: string;
  code: string;
}

interface FieldValues {
  field: string;
  path: string[];
  value: any;
}

//RETURN TYPE HOOK
export interface FormHook {
  form: any;
  errors: FormError[];
  updatableFields?: string[];
  setValue: (field: string, value: any, path?: string[]) => void;
  setValues: (vals: FieldValues[]) => void;
  bindField: (
    field: string,
    path?: string[],
    valueName?: "checked" | "value",
    defaultVal?: any,
    disabled?: boolean
  ) => any;
  bindError: (
    field: string,
    path?: string[]
  ) => { helperText: string | undefined };
  setError: (field: string, message: string, path?: string[]) => void;
  setErrors: (
    errors: { field: string; message: string; path?: string[] }[]
  ) => void;
  removeError: (field: string, path?: string[]) => void;
}

export interface UseFormProps {
  defaultValues: any;
  onSubmit: (form: any) => void;
  onChangeValidator?: (form: any) => FormError[];
  onSubmitValidatior?: (form: any) => FormError[];
  values?: any;
}

export const useBaseForm = ({
  defaultValues,
  onSubmit,
  onChangeValidator,
  values,
  onSubmitValidatior,
}: UseFormProps) => {
  const [form, setForm] = useState<any>(defaultValues);

  const [errors, setErrs] = useState<FormError[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (form && onChangeValidator) {
      setErrs(onChangeValidator(form));
    }
  }, [form, onChangeValidator]);

  useEffect(() => {
    if (values) {
      setForm(values);
    } else {
      setForm(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const getValue = (field: string, path?: string[]) => {
    return path ? _.get(form, [field, ...path]) : _.get(form, field);
  };

  const setValue = (field: string, value: any, path?: string[]) => {
    let formTemp = _.cloneDeep(form);
    if (!path) {
      _.set(formTemp, field, value);
    } else {
      _.set(formTemp, [field, ...path], value);
    }
    setForm(formTemp);
  };

  const setValues = (vals: FieldValues[]) => {
    let formTemp = _.cloneDeep(form);

    vals.forEach((el) => {
      if (el.path) {
        _.set(formTemp, [el.field, ...el.path], el.value);
      } else {
        _.set(formTemp, el.field, el.value);
      }
    });
    setForm(formTemp);
  };

  const buildErrorKey = (field: string, path?: string[]) => {
    let key = field;
    if (path) {
      key += "_" + path.join("_");
    }
    return key;
  };

  const getError = (field: string, path?: string[]) => {
    let errorsTemp = [...errors];
    let errs = errorsTemp.filter(
      (err) => err.field === buildErrorKey(field, path)
    );
    if (errs && errs.length > 0) {
      return errs[0];
    }
  };

  const setError = (field: string, message: string, path?: string[]) => {
    let errorsTemp = [...errors];
    let err = getError(field, path);
    if (err) {
      //rimuovo l'errore se presente
      errorsTemp = [
        ...errorsTemp.filter((e) => e.field !== buildErrorKey(field, path)),
      ];
    }
    errorsTemp.push({ field: buildErrorKey(field, path), code: message });
    setErrs(errorsTemp);
  };

  const setErrors = useCallback(
    (errors: { field: string; message: string; path?: string[] }[]) => {
      setErrs(
        errors.map((el) => ({
          field: buildErrorKey(el.field, el.path),
          code: el.message,
        }))
      );
    },
    []
  );

  const bindField = (
    field: string,
    path?: string[],
    valueName: "checked" | "value" = "value",
    defaultVal: any = "",
    disabled?: boolean
  ): any => {
    let name: string = field;
    let value: any = getValue(field) ?? defaultVal;
    let onChange = (e: any) => {
      removeError(field, path);
      if (valueName === "value") {
        setValue(field, e.target.value);
      } else if (valueName === "checked") {
        setValue(field, !value);
      }
    };
    let error = getError(field, path);
    if (path) {
      name = `${field}_${path.join("_")}`;
      value = getValue(field, path) || defaultVal;
      onChange = (e: any) => {
        removeError(field, path);
        if (valueName === "value") {
          setValue(field, e.target.value, path);
        } else if (valueName === "checked") {
          setValue(field, !value, path);
        }
      };
    }
    if (valueName === "value") {
      return {
        name: name,
        value: value,
        onChange: onChange,
        error: error?.field !== undefined,
        helperText: error?.code ? t(`errors.${error.code}`) : undefined,
        disabled: false || disabled,
      };
    } else if (valueName === "checked") {
      return {
        name: name,
        checked: value,
        onChange: onChange,
        error: error?.field !== undefined,
        helperText: error?.code ? t(`errors.${error.code}`) : undefined,
        disabled: false || disabled,
      };
    }
  };

  const bindError = (field: string, path?: string[]) => {
    let error = getError(field, path);
    return {
      helperText: error?.code ? t(`errors.${error.code}`) : undefined,
      error: error ? true : undefined,
    };
  };

  const removeError = (field: string, path?: string[]) => {
    let errorsTemp = [...errors];
    let err = getError(field, path);
    if (err) {
      //rimuovo l'errore se presente
      errorsTemp = [...errorsTemp.filter((e) => e.field !== field)];
      setErrs(errorsTemp);
    }
  };

  const submit = () => {
    if (onSubmitValidatior && onSubmitValidatior(form).length > 0) {
      setErrs(onSubmitValidatior(form));
    } else {
      onSubmit(form);
    }
  };

  return {
    form,
    errors,
    setValue,
    setValues,
    bindField,
    bindError,
    setErrors,
    setError,
    removeError,
    submit,
  };
};
