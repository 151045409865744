import { ReactElement } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { UserFilters } from "./UserFilters";
import { Button, Divider, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay";
import { ROUTES } from "../../../utils/const/routes";
import { useTranslation } from "react-i18next";
import { Header } from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import { PAGE_SIZE_LIST } from "../../../utils/const/conf";
import { SearchFnReturn, useBaseList } from "../../../hooks/useBaseList";
import { useApi } from "../../../context/useApi";
import { IUserResponse } from "../../../generated/axios";
import { getErrorResponse } from "../../../utils/helpers/errors";
import { useMessage } from "../../../context/useMessage";
import { getApiSort } from "../../../utils/helpers/sort";

export const UserList = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { error } = useMessage();

  const { userApi } = useApi();

  const searchFn = async (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ): Promise<SearchFnReturn<IUserResponse>> => {
    const resp = await userApi.searchUsers({
      filters: filters,
      start: start,
      size: size,
      sort: sort,
    });
    if (resp.status === 200) {
      return { total: resp.data.total ?? 0, list: resp.data.data ?? [] };
    } else {
      const err = t(`errors.${getErrorResponse(resp).code}`);
      error({ title: err });
      throw Error(err);
    }
  };

  const handleRowClick = (params: any) => {
    navigate(`${ROUTES.user}/${params.row.id}`);
  };

  const {
    list,
    loading,
    page,
    pageSize,
    total,
    sortModel,
    statefilters,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useBaseList<IUserResponse>({ searchFn });

  const columns: GridColDef[] = [
    { field: "name", headerName: t("user.name"), flex: 1 },
    { field: "email", headerName: t("user.email"), flex: 1 },
    {
      field: "roles",
      headerName: t("user.role"),
      flex: 1,
      valueGetter: (params: any) =>
        params.row.roles.map((el: string) => t(`enum.userRole.${el}`)),
    },
  ];

  const exportUsers = () => {
    userApi
      .exportUsers({
        filters: statefilters,
        size: pageSize,
        sort: getApiSort(sortModel),
        start: page * pageSize,
      })
      .then((resp) => {
        if (resp.status === 200) {
          const blob = new Blob([resp.data as any], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;

          // Set the filename
          link.setAttribute("download", "users.csv");

          // Programmatically click the link to trigger the download
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      });
  };

  return (
    <>
      <Header>
        <Stack
          spacing={3}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb options={[{ title: t("user.title"), current: true }]} />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.user);
            }}
          >
            {t("actions.new")}
          </Button>
          <Button
            startIcon={<PlaylistPlayIcon />}
            variant="outlined"
            color="secondary"
            onClick={exportUsers}
          >
            {t("actions.export")}
          </Button>
        </Stack>
      </Header>
      <UserFilters search={onFilterChange} loading={loading} />
      <DataGrid
        sx={{ height: "calc(100vh - 300px)" }}
        loading={loading}
        disableColumnMenu
        onRowClick={handleRowClick}
        columns={columns}
        rows={list}
        rowCount={total}
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={PAGE_SIZE_LIST}
        pagination
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChanged}
        keepNonExistentRowsSelected
      />
    </>
  );
};
