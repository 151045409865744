/* tslint:disable */
/* eslint-disable */
/**
 * Askoll EVA Diagnostic Backend API
 * API Rest per Askoll Diagnostic backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IAnalyticsLog
 */
export interface IAnalyticsLog {
    /**
     * 
     * @type {number}
     * @memberof IAnalyticsLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'dateUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'askollUnitSerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'type': IAnalyticsLogTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'diagnosticSwVersion': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'eventDataVersion': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'eventData': string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLog
     */
    'status'?: IAnalyticsLogStatusEnum;
}

export const IAnalyticsLogTypeEnum = {
    DiagnosticReport: 'DIAGNOSTIC_REPORT',
    FirmwareUpdate: 'FIRMWARE_UPDATE',
    FwparamsUpdate: 'FWPARAMS_UPDATE',
    Production: 'PRODUCTION',
    Replacment: 'REPLACMENT',
    ManufacturerModelEdit: 'MANUFACTURER_MODEL_EDIT'
} as const;

export type IAnalyticsLogTypeEnum = typeof IAnalyticsLogTypeEnum[keyof typeof IAnalyticsLogTypeEnum];
export const IAnalyticsLogStatusEnum = {
    Active: 'ACTIVE',
    Deleted: 'DELETED'
} as const;

export type IAnalyticsLogStatusEnum = typeof IAnalyticsLogStatusEnum[keyof typeof IAnalyticsLogStatusEnum];

/**
 * 
 * @export
 * @interface IAnalyticsLogRequest
 */
export interface IAnalyticsLogRequest {
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'askollUnitSerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'type'?: IAnalyticsLogRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'diagnosticSwVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'eventDataVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAnalyticsLogRequest
     */
    'eventData'?: string;
}

export const IAnalyticsLogRequestTypeEnum = {
    DiagnosticReport: 'DIAGNOSTIC_REPORT',
    FirmwareUpdate: 'FIRMWARE_UPDATE',
    FwparamsUpdate: 'FWPARAMS_UPDATE',
    Production: 'PRODUCTION',
    Replacment: 'REPLACMENT',
    ManufacturerModelEdit: 'MANUFACTURER_MODEL_EDIT'
} as const;

export type IAnalyticsLogRequestTypeEnum = typeof IAnalyticsLogRequestTypeEnum[keyof typeof IAnalyticsLogRequestTypeEnum];

/**
 * 
 * @export
 * @interface IAppVersionInfoResponse
 */
export interface IAppVersionInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IAppVersionInfoResponse
     */
    'isDeprecated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IAppVersionInfoResponse
     */
    'isLatest'?: boolean;
}
/**
 * 
 * @export
 * @interface IAskollKit
 */
export interface IAskollKit {
    /**
     * 
     * @type {number}
     * @memberof IAskollKit
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'dateUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'askollUnitProductId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IAskollKit
     */
    'hasAskollBattery': boolean;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'swReference': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKit
     */
    'cmReference': string;
}
/**
 * 
 * @export
 * @interface IAskollKitRequest
 */
export interface IAskollKitRequest {
    /**
     * 
     * @type {string}
     * @memberof IAskollKitRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitRequest
     */
    'askollUnitProductId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IAskollKitRequest
     */
    'hasAskollBattery': boolean;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitRequest
     */
    'swReference': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitRequest
     */
    'cmReference': string;
}
/**
 * 
 * @export
 * @interface IAskollKitResponse
 */
export interface IAskollKitResponse {
    /**
     * 
     * @type {number}
     * @memberof IAskollKitResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitResponse
     */
    'askollUnitProductId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IAskollKitResponse
     */
    'hasAskollBattery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitResponse
     */
    'swReference': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollKitResponse
     */
    'cmReference': string;
}
/**
 * 
 * @export
 * @interface IAskollUnit
 */
export interface IAskollUnit {
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'serialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'productVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'productType'?: string;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'status'?: IAskollUnitStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IAskollUnit
     */
    'dateUpdate': string;
}

export const IAskollUnitStatusEnum = {
    Active: 'ACTIVE',
    Deleted: 'DELETED'
} as const;

export type IAskollUnitStatusEnum = typeof IAskollUnitStatusEnum[keyof typeof IAskollUnitStatusEnum];

/**
 * 
 * @export
 * @interface IAuthRequest
 */
export interface IAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof IAuthRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IAuthRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof IAuthRequest
     */
    'client'?: string;
}
/**
 * 
 * @export
 * @interface IBaseResponseUserRole
 */
export interface IBaseResponseUserRole {
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseResponseUserRole
     */
    'data'?: Array<IBaseResponseUserRoleDataEnum>;
}

export const IBaseResponseUserRoleDataEnum = {
    Administrator: 'ADMINISTRATOR',
    Askoll: 'ASKOLL',
    Manufacturer: 'MANUFACTURER',
    ExtService: 'EXT_SERVICE',
    ExtServicePlus: 'EXT_SERVICE_PLUS',
    Mechanic: 'MECHANIC',
    EndUser: 'END_USER'
} as const;

export type IBaseResponseUserRoleDataEnum = typeof IBaseResponseUserRoleDataEnum[keyof typeof IBaseResponseUserRoleDataEnum];

/**
 * 
 * @export
 * @interface IBaseSearchRequestMapStringObject
 */
export interface IBaseSearchRequestMapStringObject {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof IBaseSearchRequestMapStringObject
     */
    'filters'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchRequestMapStringObject
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchRequestMapStringObject
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchRequestMapStringObject
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseAnalyticsLog
 */
export interface IBaseSearchResponseAnalyticsLog {
    /**
     * 
     * @type {Array<IAnalyticsLog>}
     * @memberof IBaseSearchResponseAnalyticsLog
     */
    'data'?: Array<IAnalyticsLog>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAnalyticsLog
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAnalyticsLog
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAnalyticsLog
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseAnalyticsLog
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseAskollKitResponse
 */
export interface IBaseSearchResponseAskollKitResponse {
    /**
     * 
     * @type {Array<IAskollKitResponse>}
     * @memberof IBaseSearchResponseAskollKitResponse
     */
    'data'?: Array<IAskollKitResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAskollKitResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAskollKitResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseAskollKitResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseAskollKitResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseCMResponse
 */
export interface IBaseSearchResponseCMResponse {
    /**
     * 
     * @type {Array<ICMResponse>}
     * @memberof IBaseSearchResponseCMResponse
     */
    'data'?: Array<ICMResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseCMResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseCMResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseCMResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseCMResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseDiagnosticAppVersionResponse
 */
export interface IBaseSearchResponseDiagnosticAppVersionResponse {
    /**
     * 
     * @type {Array<IDiagnosticAppVersionResponse>}
     * @memberof IBaseSearchResponseDiagnosticAppVersionResponse
     */
    'data'?: Array<IDiagnosticAppVersionResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseDiagnosticAppVersionResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseDiagnosticAppVersionResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseDiagnosticAppVersionResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseDiagnosticAppVersionResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseManufacturerModelResponse
 */
export interface IBaseSearchResponseManufacturerModelResponse {
    /**
     * 
     * @type {Array<IManufacturerModelResponse>}
     * @memberof IBaseSearchResponseManufacturerModelResponse
     */
    'data'?: Array<IManufacturerModelResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerModelResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerModelResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerModelResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseManufacturerModelResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseManufacturerResponse
 */
export interface IBaseSearchResponseManufacturerResponse {
    /**
     * 
     * @type {Array<IManufacturerResponse>}
     * @memberof IBaseSearchResponseManufacturerResponse
     */
    'data'?: Array<IManufacturerResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseManufacturerResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseManufacturerResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseSWResponse
 */
export interface IBaseSearchResponseSWResponse {
    /**
     * 
     * @type {Array<ISWResponse>}
     * @memberof IBaseSearchResponseSWResponse
     */
    'data'?: Array<ISWResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseSWResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseSWResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseSWResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseSWResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseString
 */
export interface IBaseSearchResponseString {
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseString
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseString
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseString
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseString
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseString
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IBaseSearchResponseUserResponse
 */
export interface IBaseSearchResponseUserResponse {
    /**
     * 
     * @type {Array<IUserResponse>}
     * @memberof IBaseSearchResponseUserResponse
     */
    'data'?: Array<IUserResponse>;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseUserResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseUserResponse
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof IBaseSearchResponseUserResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof IBaseSearchResponseUserResponse
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ICMRequest
 */
export interface ICMRequest {
    /**
     * 
     * @type {string}
     * @memberof ICMRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ICMRequest
     */
    'cm': string;
    /**
     * 
     * @type {string}
     * @memberof ICMRequest
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface ICMResponse
 */
export interface ICMResponse {
    /**
     * 
     * @type {number}
     * @memberof ICMResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ICMResponse
     */
    'cm'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICMResponse
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICMResponse
     */
    'base64data'?: string;
}
/**
 * 
 * @export
 * @interface IChangePasswordRequest
 */
export interface IChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof IChangePasswordRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof IChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface IDiagnosticAppVersionRequest
 */
export interface IDiagnosticAppVersionRequest {
    /**
     * 
     * @type {number}
     * @memberof IDiagnosticAppVersionRequest
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionRequest
     */
    'changelog'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDiagnosticAppVersionRequest
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionRequest
     */
    'type': IDiagnosticAppVersionRequestTypeEnum;
}

export const IDiagnosticAppVersionRequestTypeEnum = {
    FirstInstall: 'FIRST_INSTALL',
    Update: 'UPDATE'
} as const;

export type IDiagnosticAppVersionRequestTypeEnum = typeof IDiagnosticAppVersionRequestTypeEnum[keyof typeof IDiagnosticAppVersionRequestTypeEnum];

/**
 * 
 * @export
 * @interface IDiagnosticAppVersionResponse
 */
export interface IDiagnosticAppVersionResponse {
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof IDiagnosticAppVersionResponse
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionResponse
     */
    'changelog'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IDiagnosticAppVersionResponse
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IDiagnosticAppVersionResponse
     */
    'type'?: IDiagnosticAppVersionResponseTypeEnum;
}

export const IDiagnosticAppVersionResponseTypeEnum = {
    FirstInstall: 'FIRST_INSTALL',
    Update: 'UPDATE'
} as const;

export type IDiagnosticAppVersionResponseTypeEnum = typeof IDiagnosticAppVersionResponseTypeEnum[keyof typeof IDiagnosticAppVersionResponseTypeEnum];

/**
 * 
 * @export
 * @interface IManufacturer
 */
export interface IManufacturer {
    /**
     * 
     * @type {number}
     * @memberof IManufacturer
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IManufacturer
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturer
     */
    'dateUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturer
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturer
     */
    'name': string;
    /**
     * 
     * @type {Array<IAskollKit>}
     * @memberof IManufacturer
     */
    'askollKits'?: Array<IAskollKit>;
    /**
     * 
     * @type {string}
     * @memberof IManufacturer
     */
    'status'?: IManufacturerStatusEnum;
}

export const IManufacturerStatusEnum = {
    Active: 'ACTIVE',
    Deleted: 'DELETED'
} as const;

export type IManufacturerStatusEnum = typeof IManufacturerStatusEnum[keyof typeof IManufacturerStatusEnum];

/**
 * 
 * @export
 * @interface IManufacturerAskollKitResponse
 */
export interface IManufacturerAskollKitResponse {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerAskollKitResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerAskollKitResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerAskollKitResponse
     */
    'productId': string;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerAskollKitResponse
     */
    'hasAskollBattery'?: boolean;
}
/**
 * 
 * @export
 * @interface IManufacturerInsertRequest
 */
export interface IManufacturerInsertRequest {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerInsertRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IManufacturerInsertRequest
     */
    'askollKitIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IManufacturerModel
 */
export interface IManufacturerModel {
    /**
     * 
     * @type {number}
     * @memberof IManufacturerModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModel
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModel
     */
    'dateUpdate': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModel
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModel
     */
    'name': string;
    /**
     * 
     * @type {IManufacturer}
     * @memberof IManufacturerModel
     */
    'manufacturer': IManufacturer;
    /**
     * 
     * @type {IAskollKit}
     * @memberof IManufacturerModel
     */
    'askollKit': IAskollKit;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModel
     */
    'parameters': string;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerModel
     */
    'archived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerModel
     */
    'produced'?: boolean;
}
/**
 * 
 * @export
 * @interface IManufacturerModelRequest
 */
export interface IManufacturerModelRequest {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelRequest
     */
    'askollKitId': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelRequest
     */
    'parameters': string;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerModelRequest
     */
    'archived'?: boolean;
}
/**
 * 
 * @export
 * @interface IManufacturerModelResponse
 */
export interface IManufacturerModelResponse {
    /**
     * 
     * @type {number}
     * @memberof IManufacturerModelResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelResponse
     */
    'name': string;
    /**
     * 
     * @type {IManufacturerResponse}
     * @memberof IManufacturerModelResponse
     */
    'manufacturer': IManufacturerResponse;
    /**
     * 
     * @type {IAskollKitResponse}
     * @memberof IManufacturerModelResponse
     */
    'askollKit': IAskollKitResponse;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerModelResponse
     */
    'parameters': string;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerModelResponse
     */
    'archived': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IManufacturerModelResponse
     */
    'produced'?: boolean;
}
/**
 * 
 * @export
 * @interface IManufacturerResponse
 */
export interface IManufacturerResponse {
    /**
     * 
     * @type {number}
     * @memberof IManufacturerResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<IManufacturerAskollKitResponse>}
     * @memberof IManufacturerResponse
     */
    'askollKits'?: Array<IManufacturerAskollKitResponse>;
}
/**
 * 
 * @export
 * @interface IManufacturerUnit
 */
export interface IManufacturerUnit {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnit
     */
    'dateInsert': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnit
     */
    'dateUpdate': string;
    /**
     * 
     * @type {IManufacturerUnitId}
     * @memberof IManufacturerUnit
     */
    'manufacturerUnitId'?: IManufacturerUnitId;
    /**
     * 
     * @type {IAskollUnit}
     * @memberof IManufacturerUnit
     */
    'askollUnit'?: IAskollUnit;
    /**
     * 
     * @type {IManufacturer}
     * @memberof IManufacturerUnit
     */
    'manufacturer'?: IManufacturer;
    /**
     * 
     * @type {IManufacturerModel}
     * @memberof IManufacturerUnit
     */
    'model'?: IManufacturerModel;
}
/**
 * 
 * @export
 * @interface IManufacturerUnitId
 */
export interface IManufacturerUnitId {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnitId
     */
    'askollUnitId'?: string;
}
/**
 * 
 * @export
 * @interface IManufacturerUnitRequest
 */
export interface IManufacturerUnitRequest {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnitRequest
     */
    'manufacturerId': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnitRequest
     */
    'manufacturerModelId': string;
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUnitRequest
     */
    'askollUnitId': string;
}
/**
 * 
 * @export
 * @interface IManufacturerUnitResponse
 */
export interface IManufacturerUnitResponse {
    /**
     * 
     * @type {IManufacturerModelResponse}
     * @memberof IManufacturerUnitResponse
     */
    'model'?: IManufacturerModelResponse;
}
/**
 * 
 * @export
 * @interface IManufacturerUpdateRequest
 */
export interface IManufacturerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IManufacturerUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IManufacturerUpdateRequest
     */
    'askollKitIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface IResetPasswordConfirmRequest
 */
export interface IResetPasswordConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof IResetPasswordConfirmRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface IResetPasswordRequest
 */
export interface IResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof IResetPasswordRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ISWRequest
 */
export interface ISWRequest {
    /**
     * 
     * @type {string}
     * @memberof ISWRequest
     */
    'sw': string;
    /**
     * 
     * @type {string}
     * @memberof ISWRequest
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface ISWResponse
 */
export interface ISWResponse {
    /**
     * 
     * @type {number}
     * @memberof ISWResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ISWResponse
     */
    'sw'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISWResponse
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ISWResponse
     */
    'base64data'?: string;
}
/**
 * 
 * @export
 * @interface ISendInviteRequest
 */
export interface ISendInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof ISendInviteRequest
     */
    'emailTextLang'?: ISendInviteRequestEmailTextLangEnum;
}

export const ISendInviteRequestEmailTextLangEnum = {
    It: 'IT',
    En: 'EN'
} as const;

export type ISendInviteRequestEmailTextLangEnum = typeof ISendInviteRequestEmailTextLangEnum[keyof typeof ISendInviteRequestEmailTextLangEnum];

/**
 * 
 * @export
 * @interface IUploadAppVersionRequest
 */
export interface IUploadAppVersionRequest {
    /**
     * 
     * @type {IDiagnosticAppVersionRequest}
     * @memberof IUploadAppVersionRequest
     */
    'data': IDiagnosticAppVersionRequest;
    /**
     * 
     * @type {File}
     * @memberof IUploadAppVersionRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface IUploadCmRequest
 */
export interface IUploadCmRequest {
    /**
     * 
     * @type {ICMRequest}
     * @memberof IUploadCmRequest
     */
    'data': ICMRequest;
    /**
     * 
     * @type {File}
     * @memberof IUploadCmRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface IUploadSwRequest
 */
export interface IUploadSwRequest {
    /**
     * 
     * @type {ISWRequest}
     * @memberof IUploadSwRequest
     */
    'data': ISWRequest;
    /**
     * 
     * @type {File}
     * @memberof IUploadSwRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface IUserInsertRequest
 */
export interface IUserInsertRequest {
    /**
     * 
     * @type {string}
     * @memberof IUserInsertRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IUserInsertRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IUserInsertRequest
     */
    'enabledProducts': IUserInsertRequestEnabledProductsEnum;
    /**
     * 
     * @type {string}
     * @memberof IUserInsertRequest
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IUserInsertRequest
     */
    'roles': Array<IUserInsertRequestRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof IUserInsertRequest
     */
    'emailTextLang': IUserInsertRequestEmailTextLangEnum;
}

export const IUserInsertRequestEnabledProductsEnum = {
    Midmotor: 'MIDMOTOR',
    Scooter: 'SCOOTER',
    Kickscooter: 'KICKSCOOTER'
} as const;

export type IUserInsertRequestEnabledProductsEnum = typeof IUserInsertRequestEnabledProductsEnum[keyof typeof IUserInsertRequestEnabledProductsEnum];
export const IUserInsertRequestRolesEnum = {
    Administrator: 'ADMINISTRATOR',
    Askoll: 'ASKOLL',
    Manufacturer: 'MANUFACTURER',
    ExtService: 'EXT_SERVICE',
    ExtServicePlus: 'EXT_SERVICE_PLUS',
    Mechanic: 'MECHANIC',
    EndUser: 'END_USER'
} as const;

export type IUserInsertRequestRolesEnum = typeof IUserInsertRequestRolesEnum[keyof typeof IUserInsertRequestRolesEnum];
export const IUserInsertRequestEmailTextLangEnum = {
    It: 'IT',
    En: 'EN'
} as const;

export type IUserInsertRequestEmailTextLangEnum = typeof IUserInsertRequestEmailTextLangEnum[keyof typeof IUserInsertRequestEmailTextLangEnum];

/**
 * 
 * @export
 * @interface IUserResponse
 */
export interface IUserResponse {
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'enabledProducts'?: IUserResponseEnabledProductsEnum;
    /**
     * 
     * @type {IManufacturerResponse}
     * @memberof IUserResponse
     */
    'manufacturer'?: IManufacturerResponse;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'activationStatus'?: IUserResponseActivationStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof IUserResponse
     */
    'roles'?: Array<IUserResponseRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'accessLevel'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'fwParamsLevel'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IUserResponse
     */
    'permissions'?: Array<IUserResponsePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'insertedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserResponse
     */
    'emailTextLang'?: IUserResponseEmailTextLangEnum;
}

export const IUserResponseEnabledProductsEnum = {
    Midmotor: 'MIDMOTOR',
    Scooter: 'SCOOTER',
    Kickscooter: 'KICKSCOOTER'
} as const;

export type IUserResponseEnabledProductsEnum = typeof IUserResponseEnabledProductsEnum[keyof typeof IUserResponseEnabledProductsEnum];
export const IUserResponseActivationStatusEnum = {
    Active: 'ACTIVE',
    Waiting: 'WAITING'
} as const;

export type IUserResponseActivationStatusEnum = typeof IUserResponseActivationStatusEnum[keyof typeof IUserResponseActivationStatusEnum];
export const IUserResponseRolesEnum = {
    Administrator: 'ADMINISTRATOR',
    Askoll: 'ASKOLL',
    Manufacturer: 'MANUFACTURER',
    ExtService: 'EXT_SERVICE',
    ExtServicePlus: 'EXT_SERVICE_PLUS',
    Mechanic: 'MECHANIC',
    EndUser: 'END_USER'
} as const;

export type IUserResponseRolesEnum = typeof IUserResponseRolesEnum[keyof typeof IUserResponseRolesEnum];
export const IUserResponsePermissionsEnum = {
    LoginWeb: 'LOGIN_WEB',
    User: 'USER',
    Manufacturer: 'MANUFACTURER',
    AskollKit: 'ASKOLL_KIT',
    Firmware: 'FIRMWARE',
    Template: 'TEMPLATE',
    AppVersion: 'APP_VERSION',
    UserInsertedBy: 'USER_INSERTED_BY',
    WebManufacturerSearch: 'WEB_MANUFACTURER_SEARCH',
    LoginApp: 'LOGIN_APP',
    AppAskollKit: 'APP_ASKOLL_KIT',
    AppManufacturerModel: 'APP_MANUFACTURER_MODEL',
    AppManufacturerModelSearch: 'APP_MANUFACTURER_MODEL_SEARCH',
    AppManufacturerUnit: 'APP_MANUFACTURER_UNIT',
    AppLastCm: 'APP_LAST_CM',
    AppLastSw: 'APP_LAST_SW',
    AppAnalyticsLog: 'APP_ANALYTICS_LOG',
    AppGetManufacturerUnit: 'APP_GET_MANUFACTURER_UNIT',
    GetUser: 'GET_USER',
    GetAskollUnit: 'GET_ASKOLL_UNIT'
} as const;

export type IUserResponsePermissionsEnum = typeof IUserResponsePermissionsEnum[keyof typeof IUserResponsePermissionsEnum];
export const IUserResponseEmailTextLangEnum = {
    It: 'IT',
    En: 'EN'
} as const;

export type IUserResponseEmailTextLangEnum = typeof IUserResponseEmailTextLangEnum[keyof typeof IUserResponseEmailTextLangEnum];

/**
 * 
 * @export
 * @interface IUserUpdateRequest
 */
export interface IUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'enabledProducts': IUserUpdateRequestEnabledProductsEnum;
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'manufacturerId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IUserUpdateRequest
     */
    'roles': Array<IUserUpdateRequestRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'insertedFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof IUserUpdateRequest
     */
    'emailTextLang': IUserUpdateRequestEmailTextLangEnum;
}

export const IUserUpdateRequestEnabledProductsEnum = {
    Midmotor: 'MIDMOTOR',
    Scooter: 'SCOOTER',
    Kickscooter: 'KICKSCOOTER'
} as const;

export type IUserUpdateRequestEnabledProductsEnum = typeof IUserUpdateRequestEnabledProductsEnum[keyof typeof IUserUpdateRequestEnabledProductsEnum];
export const IUserUpdateRequestRolesEnum = {
    Administrator: 'ADMINISTRATOR',
    Askoll: 'ASKOLL',
    Manufacturer: 'MANUFACTURER',
    ExtService: 'EXT_SERVICE',
    ExtServicePlus: 'EXT_SERVICE_PLUS',
    Mechanic: 'MECHANIC',
    EndUser: 'END_USER'
} as const;

export type IUserUpdateRequestRolesEnum = typeof IUserUpdateRequestRolesEnum[keyof typeof IUserUpdateRequestRolesEnum];
export const IUserUpdateRequestEmailTextLangEnum = {
    It: 'IT',
    En: 'EN'
} as const;

export type IUserUpdateRequestEmailTextLangEnum = typeof IUserUpdateRequestEmailTextLangEnum[keyof typeof IUserUpdateRequestEmailTextLangEnum];


/**
 * AccessControllerApi - axios parameter creator
 * @export
 */
export const AccessControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IAuthRequest} iAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (iAuthRequest: IAuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iAuthRequest' is not null or undefined
            assertParamExists('login', 'iAuthRequest', iAuthRequest)
            const localVarPath = `/v1/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAuthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessControllerApi - functional programming interface
 * @export
 */
export const AccessControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IAuthRequest} iAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(iAuthRequest: IAuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(iAuthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessControllerApi - factory interface
 * @export
 */
export const AccessControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {IAuthRequest} iAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(iAuthRequest: IAuthRequest, options?: any): AxiosPromise<object> {
            return localVarFp.login(iAuthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessControllerApi - object-oriented interface
 * @export
 * @class AccessControllerApi
 * @extends {BaseAPI}
 */
export class AccessControllerApi extends BaseAPI {
    /**
     * 
     * @param {IAuthRequest} iAuthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControllerApi
     */
    public login(iAuthRequest: IAuthRequest, options?: AxiosRequestConfig) {
        return AccessControllerApiFp(this.configuration).login(iAuthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControllerApi
     */
    public logout(options?: AxiosRequestConfig) {
        return AccessControllerApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsLogControllerApi - axios parameter creator
 * @export
 */
export const AnalyticsLogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsLog: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnalyticsLog', 'id', id)
            const localVarPath = `/v1/analytics-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IAnalyticsLogRequest} iAnalyticsLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAnalyticsLog: async (iAnalyticsLogRequest: IAnalyticsLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iAnalyticsLogRequest' is not null or undefined
            assertParamExists('newAnalyticsLog', 'iAnalyticsLogRequest', iAnalyticsLogRequest)
            const localVarPath = `/v1/analytics-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAnalyticsLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnalyticsLogs: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchAnalyticsLogs', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/analytics-log/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsLogControllerApi - functional programming interface
 * @export
 */
export const AnalyticsLogControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsLogControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsLog(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAnalyticsLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsLog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IAnalyticsLogRequest} iAnalyticsLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAnalyticsLog(iAnalyticsLogRequest: IAnalyticsLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAnalyticsLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAnalyticsLog(iAnalyticsLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAnalyticsLogs(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseAnalyticsLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAnalyticsLogs(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsLogControllerApi - factory interface
 * @export
 */
export const AnalyticsLogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsLogControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsLog(id: string, options?: any): AxiosPromise<IAnalyticsLog> {
            return localVarFp.getAnalyticsLog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IAnalyticsLogRequest} iAnalyticsLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAnalyticsLog(iAnalyticsLogRequest: IAnalyticsLogRequest, options?: any): AxiosPromise<IAnalyticsLog> {
            return localVarFp.newAnalyticsLog(iAnalyticsLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnalyticsLogs(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseAnalyticsLog> {
            return localVarFp.searchAnalyticsLogs(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsLogControllerApi - object-oriented interface
 * @export
 * @class AnalyticsLogControllerApi
 * @extends {BaseAPI}
 */
export class AnalyticsLogControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsLogControllerApi
     */
    public getAnalyticsLog(id: string, options?: AxiosRequestConfig) {
        return AnalyticsLogControllerApiFp(this.configuration).getAnalyticsLog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IAnalyticsLogRequest} iAnalyticsLogRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsLogControllerApi
     */
    public newAnalyticsLog(iAnalyticsLogRequest: IAnalyticsLogRequest, options?: AxiosRequestConfig) {
        return AnalyticsLogControllerApiFp(this.configuration).newAnalyticsLog(iAnalyticsLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsLogControllerApi
     */
    public searchAnalyticsLogs(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return AnalyticsLogControllerApiFp(this.configuration).searchAnalyticsLogs(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AskollKitControllerApi - axios parameter creator
 * @export
 */
export const AskollKitControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAskollKit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAskollKit', 'id', id)
            const localVarPath = `/v1/askoll-kit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollKit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAskollKit', 'id', id)
            const localVarPath = `/v1/askoll-kit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollKitByManufacturerId: async (manufacturerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manufacturerId' is not null or undefined
            assertParamExists('getAskollKitByManufacturerId', 'manufacturerId', manufacturerId)
            const localVarPath = `/v1/askoll-kit/get-by-manufacturer-id/{manufacturerId}`
                .replace(`{${"manufacturerId"}}`, encodeURIComponent(String(manufacturerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAskollKit: async (iAskollKitRequest: IAskollKitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iAskollKitRequest' is not null or undefined
            assertParamExists('newAskollKit', 'iAskollKitRequest', iAskollKitRequest)
            const localVarPath = `/v1/askoll-kit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAskollKitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAskollKit: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchAskollKit', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/askoll-kit/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAskollKit: async (id: string, iAskollKitRequest: IAskollKitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAskollKit', 'id', id)
            // verify required parameter 'iAskollKitRequest' is not null or undefined
            assertParamExists('updateAskollKit', 'iAskollKitRequest', iAskollKitRequest)
            const localVarPath = `/v1/askoll-kit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iAskollKitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskollKitControllerApi - functional programming interface
 * @export
 */
export const AskollKitControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskollKitControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAskollKit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAskollKit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAskollKit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAskollKitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAskollKit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAskollKitByManufacturerId(manufacturerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IAskollKitResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAskollKitByManufacturerId(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAskollKit(iAskollKitRequest: IAskollKitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAskollKitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAskollKit(iAskollKitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAskollKit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseAskollKitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAskollKit(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAskollKit(id: string, iAskollKitRequest: IAskollKitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAskollKitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAskollKit(id, iAskollKitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskollKitControllerApi - factory interface
 * @export
 */
export const AskollKitControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskollKitControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAskollKit(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAskollKit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollKit(id: string, options?: any): AxiosPromise<IAskollKitResponse> {
            return localVarFp.getAskollKit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manufacturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollKitByManufacturerId(manufacturerId: string, options?: any): AxiosPromise<Array<IAskollKitResponse>> {
            return localVarFp.getAskollKitByManufacturerId(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAskollKit(iAskollKitRequest: IAskollKitRequest, options?: any): AxiosPromise<IAskollKitResponse> {
            return localVarFp.newAskollKit(iAskollKitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAskollKit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseAskollKitResponse> {
            return localVarFp.searchAskollKit(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {IAskollKitRequest} iAskollKitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAskollKit(id: string, iAskollKitRequest: IAskollKitRequest, options?: any): AxiosPromise<IAskollKitResponse> {
            return localVarFp.updateAskollKit(id, iAskollKitRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskollKitControllerApi - object-oriented interface
 * @export
 * @class AskollKitControllerApi
 * @extends {BaseAPI}
 */
export class AskollKitControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public deleteAskollKit(id: string, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).deleteAskollKit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public getAskollKit(id: string, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).getAskollKit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manufacturerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public getAskollKitByManufacturerId(manufacturerId: string, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).getAskollKitByManufacturerId(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IAskollKitRequest} iAskollKitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public newAskollKit(iAskollKitRequest: IAskollKitRequest, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).newAskollKit(iAskollKitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public searchAskollKit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).searchAskollKit(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {IAskollKitRequest} iAskollKitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollKitControllerApi
     */
    public updateAskollKit(id: string, iAskollKitRequest: IAskollKitRequest, options?: AxiosRequestConfig) {
        return AskollKitControllerApiFp(this.configuration).updateAskollKit(id, iAskollKitRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AskollUnitControllerApi - axios parameter creator
 * @export
 */
export const AskollUnitControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDistinctAskollUnit: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('getAllDistinctAskollUnit', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/askoll-unit/get-all-distinct-askoll-units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollUnit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAskollUnit', 'id', id)
            const localVarPath = `/v1/askoll-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AskollUnitControllerApi - functional programming interface
 * @export
 */
export const AskollUnitControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AskollUnitControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAskollUnit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAskollUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAskollUnit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AskollUnitControllerApi - factory interface
 * @export
 */
export const AskollUnitControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AskollUnitControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseString> {
            return localVarFp.getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAskollUnit(id: string, options?: any): AxiosPromise<IAskollUnit> {
            return localVarFp.getAskollUnit(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AskollUnitControllerApi - object-oriented interface
 * @export
 * @class AskollUnitControllerApi
 * @extends {BaseAPI}
 */
export class AskollUnitControllerApi extends BaseAPI {
    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollUnitControllerApi
     */
    public getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return AskollUnitControllerApiFp(this.configuration).getAllDistinctAskollUnit(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AskollUnitControllerApi
     */
    public getAskollUnit(id: string, options?: AxiosRequestConfig) {
        return AskollUnitControllerApiFp(this.configuration).getAskollUnit(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmControllerApi - axios parameter creator
 * @export
 */
export const CmControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCm: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCm', 'id', id)
            const localVarPath = `/v1/cm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCmUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadCmUrl', 'id', id)
            const localVarPath = `/v1/cm/{id}/download-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCm: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCm', 'id', id)
            const localVarPath = `/v1/cm/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastCm: async (cm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cm' is not null or undefined
            assertParamExists('getLastCm', 'cm', cm)
            const localVarPath = `/v1/cm/last/{cm}`
                .replace(`{${"cm"}}`, encodeURIComponent(String(cm)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCms: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchCms', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/cm/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDistinctCms: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchDistinctCms', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/cm/search-distinct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ICMRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCm: async (data: ICMRequest, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('uploadCm', 'data', data)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadCm', 'file', file)
            const localVarPath = `/v1/cm/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmControllerApi - functional programming interface
 * @export
 */
export const CmControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCm(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCmUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCmUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCm(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} cm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastCm(cm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastCm(cm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseCMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCms(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDistinctCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseCMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDistinctCms(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ICMRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCm(data: ICMRequest, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICMResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCm(data, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CmControllerApi - factory interface
 * @export
 */
export const CmControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCm(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteCm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCmUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadCmUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCm(id: string, options?: any): AxiosPromise<ICMResponse> {
            return localVarFp.getCm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} cm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastCm(cm: string, options?: any): AxiosPromise<ICMResponse> {
            return localVarFp.getLastCm(cm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseCMResponse> {
            return localVarFp.searchCms(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDistinctCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseCMResponse> {
            return localVarFp.searchDistinctCms(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ICMRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCm(data: ICMRequest, file: File, options?: any): AxiosPromise<ICMResponse> {
            return localVarFp.uploadCm(data, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmControllerApi - object-oriented interface
 * @export
 * @class CmControllerApi
 * @extends {BaseAPI}
 */
export class CmControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public deleteCm(id: string, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).deleteCm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public downloadCmUrl(id: string, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).downloadCmUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public getCm(id: string, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).getCm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} cm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public getLastCm(cm: string, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).getLastCm(cm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public searchCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).searchCms(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public searchDistinctCms(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).searchDistinctCms(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ICMRequest} data 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmControllerApi
     */
    public uploadCm(data: ICMRequest, file: File, options?: AxiosRequestConfig) {
        return CmControllerApiFp(this.configuration).uploadCm(data, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiagnosticAppVersionControllerApi - axios parameter creator
 * @export
 */
export const DiagnosticAppVersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppVersion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAppVersion', 'id', id)
            const localVarPath = `/v1/diagnostic-app-version/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateAppVersion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deprecateAppVersion', 'id', id)
            const localVarPath = `/v1/diagnostic-app-version/deprecate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAppVersion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadAppVersion', 'id', id)
            const localVarPath = `/v1/diagnostic-app-version/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAppVersionUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadAppVersionUrl', 'id', id)
            const localVarPath = `/v1/diagnostic-app-version/{id}/download-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAppVersions: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchAppVersions', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/diagnostic-app-version/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IDiagnosticAppVersionRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppVersion: async (data: IDiagnosticAppVersionRequest, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('uploadAppVersion', 'data', data)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAppVersion', 'file', file)
            const localVarPath = `/v1/diagnostic-app-version/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionInfo: async (type: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('versionInfo', 'type', type)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('versionInfo', 'code', code)
            const localVarPath = `/v1/diagnostic-app-version/version-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosticAppVersionControllerApi - functional programming interface
 * @export
 */
export const DiagnosticAppVersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosticAppVersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppVersion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecateAppVersion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecateAppVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAppVersion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAppVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAppVersionUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAppVersionUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAppVersions(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseDiagnosticAppVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAppVersions(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IDiagnosticAppVersionRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAppVersion(data: IDiagnosticAppVersionRequest, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDiagnosticAppVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAppVersion(data, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionInfo(type: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAppVersionInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionInfo(type, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiagnosticAppVersionControllerApi - factory interface
 * @export
 */
export const DiagnosticAppVersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosticAppVersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppVersion(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteAppVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecateAppVersion(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deprecateAppVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAppVersion(id: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadAppVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAppVersionUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadAppVersionUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAppVersions(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseDiagnosticAppVersionResponse> {
            return localVarFp.searchAppVersions(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IDiagnosticAppVersionRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAppVersion(data: IDiagnosticAppVersionRequest, file: File, options?: any): AxiosPromise<IDiagnosticAppVersionResponse> {
            return localVarFp.uploadAppVersion(data, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionInfo(type: string, code: string, options?: any): AxiosPromise<IAppVersionInfoResponse> {
            return localVarFp.versionInfo(type, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosticAppVersionControllerApi - object-oriented interface
 * @export
 * @class DiagnosticAppVersionControllerApi
 * @extends {BaseAPI}
 */
export class DiagnosticAppVersionControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public deleteAppVersion(id: string, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).deleteAppVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public deprecateAppVersion(id: string, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).deprecateAppVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public downloadAppVersion(id: string, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).downloadAppVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public downloadAppVersionUrl(id: string, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).downloadAppVersionUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public searchAppVersions(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).searchAppVersions(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IDiagnosticAppVersionRequest} data 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public uploadAppVersion(data: IDiagnosticAppVersionRequest, file: File, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).uploadAppVersion(data, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosticAppVersionControllerApi
     */
    public versionInfo(type: string, code: string, options?: AxiosRequestConfig) {
        return DiagnosticAppVersionControllerApiFp(this.configuration).versionInfo(type, code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckControllerApi - axios parameter creator
 * @export
 */
export const HealthCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/utils/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */
export const HealthCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckControllerApi - factory interface
 * @export
 */
export const HealthCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<object> {
            return localVarFp.healthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */
export class HealthCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckControllerApi
     */
    public healthCheck(options?: AxiosRequestConfig) {
        return HealthCheckControllerApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerControllerApi - axios parameter creator
 * @export
 */
export const ManufacturerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getManufacturer', 'id', id)
            const localVarPath = `/v1/manufacturer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IManufacturerInsertRequest} iManufacturerInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManufacturer: async (iManufacturerInsertRequest: IManufacturerInsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iManufacturerInsertRequest' is not null or undefined
            assertParamExists('insertManufacturer', 'iManufacturerInsertRequest', iManufacturerInsertRequest)
            const localVarPath = `/v1/manufacturer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iManufacturerInsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturers: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchManufacturers', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/manufacturer/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerUpdateRequest} iManufacturerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer: async (id: string, iManufacturerUpdateRequest: IManufacturerUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManufacturer', 'id', id)
            // verify required parameter 'iManufacturerUpdateRequest' is not null or undefined
            assertParamExists('updateManufacturer', 'iManufacturerUpdateRequest', iManufacturerUpdateRequest)
            const localVarPath = `/v1/manufacturer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iManufacturerUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerControllerApi - functional programming interface
 * @export
 */
export const ManufacturerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IManufacturerInsertRequest} iManufacturerInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertManufacturer(iManufacturerInsertRequest: IManufacturerInsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertManufacturer(iManufacturerInsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchManufacturers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchManufacturers(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerUpdateRequest} iManufacturerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturer(id: string, iManufacturerUpdateRequest: IManufacturerUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturer(id, iManufacturerUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerControllerApi - factory interface
 * @export
 */
export const ManufacturerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturer(id: string, options?: any): AxiosPromise<IManufacturerResponse> {
            return localVarFp.getManufacturer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IManufacturerInsertRequest} iManufacturerInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertManufacturer(iManufacturerInsertRequest: IManufacturerInsertRequest, options?: any): AxiosPromise<IManufacturerResponse> {
            return localVarFp.insertManufacturer(iManufacturerInsertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseManufacturerResponse> {
            return localVarFp.searchManufacturers(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerUpdateRequest} iManufacturerUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturer(id: string, iManufacturerUpdateRequest: IManufacturerUpdateRequest, options?: any): AxiosPromise<IManufacturerResponse> {
            return localVarFp.updateManufacturer(id, iManufacturerUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerControllerApi - object-oriented interface
 * @export
 * @class ManufacturerControllerApi
 * @extends {BaseAPI}
 */
export class ManufacturerControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerControllerApi
     */
    public getManufacturer(id: string, options?: AxiosRequestConfig) {
        return ManufacturerControllerApiFp(this.configuration).getManufacturer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IManufacturerInsertRequest} iManufacturerInsertRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerControllerApi
     */
    public insertManufacturer(iManufacturerInsertRequest: IManufacturerInsertRequest, options?: AxiosRequestConfig) {
        return ManufacturerControllerApiFp(this.configuration).insertManufacturer(iManufacturerInsertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerControllerApi
     */
    public searchManufacturers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return ManufacturerControllerApiFp(this.configuration).searchManufacturers(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {IManufacturerUpdateRequest} iManufacturerUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerControllerApi
     */
    public updateManufacturer(id: string, iManufacturerUpdateRequest: IManufacturerUpdateRequest, options?: AxiosRequestConfig) {
        return ManufacturerControllerApiFp(this.configuration).updateManufacturer(id, iManufacturerUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerModelControllerApi - axios parameter creator
 * @export
 */
export const ManufacturerModelControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturerModel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteManufacturerModel', 'id', id)
            const localVarPath = `/v1/manufacturer-model/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerModel: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getManufacturerModel', 'id', id)
            const localVarPath = `/v1/manufacturer-model/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newManufacturerModel: async (iManufacturerModelRequest: IManufacturerModelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iManufacturerModelRequest' is not null or undefined
            assertParamExists('newManufacturerModel', 'iManufacturerModelRequest', iManufacturerModelRequest)
            const localVarPath = `/v1/manufacturer-model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iManufacturerModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturerModels: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchManufacturerModels', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/manufacturer-model/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProduced: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setProduced', 'id', id)
            const localVarPath = `/v1/manufacturer-model/{id}/set-produced`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerModel: async (id: string, iManufacturerModelRequest: IManufacturerModelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateManufacturerModel', 'id', id)
            // verify required parameter 'iManufacturerModelRequest' is not null or undefined
            assertParamExists('updateManufacturerModel', 'iManufacturerModelRequest', iManufacturerModelRequest)
            const localVarPath = `/v1/manufacturer-model/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iManufacturerModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerModelControllerApi - functional programming interface
 * @export
 */
export const ManufacturerModelControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerModelControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManufacturerModel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManufacturerModel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturerModel(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturerModel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newManufacturerModel(iManufacturerModelRequest: IManufacturerModelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newManufacturerModel(iManufacturerModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchManufacturerModels(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseManufacturerModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchManufacturerModels(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProduced(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProduced(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManufacturerModel(id: string, iManufacturerModelRequest: IManufacturerModelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManufacturerModel(id, iManufacturerModelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerModelControllerApi - factory interface
 * @export
 */
export const ManufacturerModelControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerModelControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturerModel(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManufacturerModel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerModel(id: string, options?: any): AxiosPromise<IManufacturerModelResponse> {
            return localVarFp.getManufacturerModel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newManufacturerModel(iManufacturerModelRequest: IManufacturerModelRequest, options?: any): AxiosPromise<IManufacturerModelResponse> {
            return localVarFp.newManufacturerModel(iManufacturerModelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturerModels(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseManufacturerModelResponse> {
            return localVarFp.searchManufacturerModels(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProduced(id: string, options?: any): AxiosPromise<IManufacturerModelResponse> {
            return localVarFp.setProduced(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {IManufacturerModelRequest} iManufacturerModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManufacturerModel(id: string, iManufacturerModelRequest: IManufacturerModelRequest, options?: any): AxiosPromise<IManufacturerModelResponse> {
            return localVarFp.updateManufacturerModel(id, iManufacturerModelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerModelControllerApi - object-oriented interface
 * @export
 * @class ManufacturerModelControllerApi
 * @extends {BaseAPI}
 */
export class ManufacturerModelControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public deleteManufacturerModel(id: string, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).deleteManufacturerModel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public getManufacturerModel(id: string, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).getManufacturerModel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IManufacturerModelRequest} iManufacturerModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public newManufacturerModel(iManufacturerModelRequest: IManufacturerModelRequest, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).newManufacturerModel(iManufacturerModelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public searchManufacturerModels(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).searchManufacturerModels(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public setProduced(id: string, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).setProduced(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {IManufacturerModelRequest} iManufacturerModelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerModelControllerApi
     */
    public updateManufacturerModel(id: string, iManufacturerModelRequest: IManufacturerModelRequest, options?: AxiosRequestConfig) {
        return ManufacturerModelControllerApiFp(this.configuration).updateManufacturerModel(id, iManufacturerModelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManufacturerUnitControllerApi - axios parameter creator
 * @export
 */
export const ManufacturerUnitControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturerUnit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteManufacturerUnit', 'id', id)
            const localVarPath = `/v1/manufacturer-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerUnit: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getManufacturerUnit', 'id', id)
            const localVarPath = `/v1/manufacturer-unit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IManufacturerUnitRequest} iManufacturerUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertOrUpdateManufacturerUnit: async (iManufacturerUnitRequest: IManufacturerUnitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iManufacturerUnitRequest' is not null or undefined
            assertParamExists('insertOrUpdateManufacturerUnit', 'iManufacturerUnitRequest', iManufacturerUnitRequest)
            const localVarPath = `/v1/manufacturer-unit/insert-or-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iManufacturerUnitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturerUnits: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchManufacturerUnits', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/manufacturer-unit/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturerUnitControllerApi - functional programming interface
 * @export
 */
export const ManufacturerUnitControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturerUnitControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManufacturerUnit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManufacturerUnit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManufacturerUnit(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManufacturerUnitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManufacturerUnit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IManufacturerUnitRequest} iManufacturerUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertOrUpdateManufacturerUnit(iManufacturerUnitRequest: IManufacturerUnitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertOrUpdateManufacturerUnit(iManufacturerUnitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchManufacturerUnits(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IManufacturerUnit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchManufacturerUnits(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManufacturerUnitControllerApi - factory interface
 * @export
 */
export const ManufacturerUnitControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturerUnitControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManufacturerUnit(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManufacturerUnit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManufacturerUnit(id: string, options?: any): AxiosPromise<IManufacturerUnitResponse> {
            return localVarFp.getManufacturerUnit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IManufacturerUnitRequest} iManufacturerUnitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertOrUpdateManufacturerUnit(iManufacturerUnitRequest: IManufacturerUnitRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.insertOrUpdateManufacturerUnit(iManufacturerUnitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchManufacturerUnits(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<Array<IManufacturerUnit>> {
            return localVarFp.searchManufacturerUnits(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturerUnitControllerApi - object-oriented interface
 * @export
 * @class ManufacturerUnitControllerApi
 * @extends {BaseAPI}
 */
export class ManufacturerUnitControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerUnitControllerApi
     */
    public deleteManufacturerUnit(id: string, options?: AxiosRequestConfig) {
        return ManufacturerUnitControllerApiFp(this.configuration).deleteManufacturerUnit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerUnitControllerApi
     */
    public getManufacturerUnit(id: string, options?: AxiosRequestConfig) {
        return ManufacturerUnitControllerApiFp(this.configuration).getManufacturerUnit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IManufacturerUnitRequest} iManufacturerUnitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerUnitControllerApi
     */
    public insertOrUpdateManufacturerUnit(iManufacturerUnitRequest: IManufacturerUnitRequest, options?: AxiosRequestConfig) {
        return ManufacturerUnitControllerApiFp(this.configuration).insertOrUpdateManufacturerUnit(iManufacturerUnitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturerUnitControllerApi
     */
    public searchManufacturerUnits(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return ManufacturerUnitControllerApiFp(this.configuration).searchManufacturerUnits(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicFileDownloadControllerApi - axios parameter creator
 * @export
 */
export const PublicFileDownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestAppUpdate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/release/latest-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestFullApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/release/latest-full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicFileDownloadControllerApi - functional programming interface
 * @export
 */
export const PublicFileDownloadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicFileDownloadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latestAppUpdate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latestAppUpdate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async latestFullApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.latestFullApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicFileDownloadControllerApi - factory interface
 * @export
 */
export const PublicFileDownloadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicFileDownloadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestAppUpdate(options?: any): AxiosPromise<File> {
            return localVarFp.latestAppUpdate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestFullApp(options?: any): AxiosPromise<File> {
            return localVarFp.latestFullApp(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicFileDownloadControllerApi - object-oriented interface
 * @export
 * @class PublicFileDownloadControllerApi
 * @extends {BaseAPI}
 */
export class PublicFileDownloadControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicFileDownloadControllerApi
     */
    public latestAppUpdate(options?: AxiosRequestConfig) {
        return PublicFileDownloadControllerApiFp(this.configuration).latestAppUpdate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicFileDownloadControllerApi
     */
    public latestFullApp(options?: AxiosRequestConfig) {
        return PublicFileDownloadControllerApiFp(this.configuration).latestFullApp(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SwControllerApi - axios parameter creator
 * @export
 */
export const SwControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSw: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSw', 'id', id)
            const localVarPath = `/v1/sw/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSwUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadSwUrl', 'id', id)
            const localVarPath = `/v1/sw/{id}/download-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSw: async (sw: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sw' is not null or undefined
            assertParamExists('getLastSw', 'sw', sw)
            const localVarPath = `/v1/sw/last/{sw}`
                .replace(`{${"sw"}}`, encodeURIComponent(String(sw)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSw: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSw', 'id', id)
            const localVarPath = `/v1/sw/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDistinctSws: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchDistinctSws', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/sw/search-distinct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSws: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchSws', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/sw/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ISWRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSw: async (data: ISWRequest, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('uploadSw', 'data', data)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadSw', 'file', file)
            const localVarPath = `/v1/sw/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', new Blob([JSON.stringify(data)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SwControllerApi - functional programming interface
 * @export
 */
export const SwControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SwControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSw(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSw(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSwUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSwUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastSw(sw: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISWResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastSw(sw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSw(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISWResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSw(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDistinctSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseSWResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDistinctSws(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseSWResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSws(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ISWRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSw(data: ISWRequest, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ISWResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSw(data, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SwControllerApi - factory interface
 * @export
 */
export const SwControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SwControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSw(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteSw(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSwUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadSwUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSw(sw: string, options?: any): AxiosPromise<ISWResponse> {
            return localVarFp.getLastSw(sw, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSw(id: string, options?: any): AxiosPromise<ISWResponse> {
            return localVarFp.getSw(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDistinctSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseSWResponse> {
            return localVarFp.searchDistinctSws(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseSWResponse> {
            return localVarFp.searchSws(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ISWRequest} data 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSw(data: ISWRequest, file: File, options?: any): AxiosPromise<ISWResponse> {
            return localVarFp.uploadSw(data, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SwControllerApi - object-oriented interface
 * @export
 * @class SwControllerApi
 * @extends {BaseAPI}
 */
export class SwControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public deleteSw(id: string, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).deleteSw(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public downloadSwUrl(id: string, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).downloadSwUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sw 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public getLastSw(sw: string, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).getLastSw(sw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public getSw(id: string, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).getSw(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public searchDistinctSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).searchDistinctSws(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public searchSws(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).searchSws(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ISWRequest} data 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwControllerApi
     */
    public uploadSw(data: ISWRequest, file: File, options?: AxiosRequestConfig) {
        return SwControllerApiFp(this.configuration).uploadSw(data, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IChangePasswordRequest} iChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (iChangePasswordRequest: IChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iChangePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'iChangePasswordRequest', iChangePasswordRequest)
            const localVarPath = `/v1/user/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsers: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('exportUsers', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/user/export-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogged: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/logged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsername: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUserByUsername', 'username', username)
            const localVarPath = `/v1/user/by-username/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/my-user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IUserInsertRequest} iUserInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUser: async (iUserInsertRequest: IUserInsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUserInsertRequest' is not null or undefined
            assertParamExists('newUser', 'iUserInsertRequest', iUserInsertRequest)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUserInsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IResetPasswordRequest} iResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest: async (iResetPasswordRequest: IResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iResetPasswordRequest' is not null or undefined
            assertParamExists('resetPasswordRequest', 'iResetPasswordRequest', iResetPasswordRequest)
            const localVarPath = `/v1/user/public/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {IResetPasswordConfirmRequest} iResetPasswordConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordSubmit: async (token: string, iResetPasswordConfirmRequest: IResetPasswordConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('resetPasswordSubmit', 'token', token)
            // verify required parameter 'iResetPasswordConfirmRequest' is not null or undefined
            assertParamExists('resetPasswordSubmit', 'iResetPasswordConfirmRequest', iResetPasswordConfirmRequest)
            const localVarPath = `/v1/user/public/reset-password/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iResetPasswordConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iBaseSearchRequestMapStringObject' is not null or undefined
            assertParamExists('searchUsers', 'iBaseSearchRequestMapStringObject', iBaseSearchRequestMapStringObject)
            const localVarPath = `/v1/user/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iBaseSearchRequestMapStringObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ISendInviteRequest} iSendInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: async (id: string, iSendInviteRequest: ISendInviteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendInvite', 'id', id)
            // verify required parameter 'iSendInviteRequest' is not null or undefined
            assertParamExists('sendInvite', 'iSendInviteRequest', iSendInviteRequest)
            const localVarPath = `/v1/user/{id}/send-invite`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iSendInviteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/public/send-mail-test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {IUserUpdateRequest} iUserUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, iUserUpdateRequest: IUserUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'iUserUpdateRequest' is not null or undefined
            assertParamExists('updateUser', 'iUserUpdateRequest', iUserUpdateRequest)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUserUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRecoveryToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validatePasswordRecoveryToken', 'token', token)
            const localVarPath = `/v1/user/public/validate-token/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-ACCESS-TOKEN required
            await setApiKeyToObject(localVarHeaderParameter, "X-ACCESS-TOKEN", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IChangePasswordRequest} iChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(iChangePasswordRequest: IChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(iChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportUsers(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogged(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogged(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByUsername(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByUsername(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseResponseUserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IUserInsertRequest} iUserInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUser(iUserInsertRequest: IUserInsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newUser(iUserInsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IResetPasswordRequest} iResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordRequest(iResetPasswordRequest: IResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordRequest(iResetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {IResetPasswordConfirmRequest} iResetPasswordConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordSubmit(token: string, iResetPasswordConfirmRequest: IResetPasswordConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordSubmit(token, iResetPasswordConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBaseSearchResponseUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(iBaseSearchRequestMapStringObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ISendInviteRequest} iSendInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvite(id: string, iSendInviteRequest: ISendInviteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvite(id, iSendInviteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testEmail(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {IUserUpdateRequest} iUserUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, iUserUpdateRequest: IUserUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, iUserUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePasswordRecoveryToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePasswordRecoveryToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {IChangePasswordRequest} iChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(iChangePasswordRequest: IChangePasswordRequest, options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.changePassword(iChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<File> {
            return localVarFp.exportUsers(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogged(options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.getLogged(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByUsername(username: string, options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.getUserByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles(options?: any): AxiosPromise<IBaseResponseUserRole> {
            return localVarFp.getUserRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IUserInsertRequest} iUserInsertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUser(iUserInsertRequest: IUserInsertRequest, options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.newUser(iUserInsertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IResetPasswordRequest} iResetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(iResetPasswordRequest: IResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetPasswordRequest(iResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {IResetPasswordConfirmRequest} iResetPasswordConfirmRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordSubmit(token: string, iResetPasswordConfirmRequest: IResetPasswordConfirmRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.resetPasswordSubmit(token, iResetPasswordConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: any): AxiosPromise<IBaseSearchResponseUserResponse> {
            return localVarFp.searchUsers(iBaseSearchRequestMapStringObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ISendInviteRequest} iSendInviteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(id: string, iSendInviteRequest: ISendInviteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendInvite(id, iSendInviteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testEmail(options?: any): AxiosPromise<void> {
            return localVarFp.testEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {IUserUpdateRequest} iUserUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, iUserUpdateRequest: IUserUpdateRequest, options?: any): AxiosPromise<IUserResponse> {
            return localVarFp.updateUser(id, iUserUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRecoveryToken(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.validatePasswordRecoveryToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {IChangePasswordRequest} iChangePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(iChangePasswordRequest: IChangePasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).changePassword(iChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public exportUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).exportUsers(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getLogged(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getLogged(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByUsername(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserRoles(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IUserInsertRequest} iUserInsertRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public newUser(iUserInsertRequest: IUserInsertRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).newUser(iUserInsertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IResetPasswordRequest} iResetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPasswordRequest(iResetPasswordRequest: IResetPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPasswordRequest(iResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {IResetPasswordConfirmRequest} iResetPasswordConfirmRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPasswordSubmit(token: string, iResetPasswordConfirmRequest: IResetPasswordConfirmRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resetPasswordSubmit(token, iResetPasswordConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IBaseSearchRequestMapStringObject} iBaseSearchRequestMapStringObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchUsers(iBaseSearchRequestMapStringObject: IBaseSearchRequestMapStringObject, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).searchUsers(iBaseSearchRequestMapStringObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ISendInviteRequest} iSendInviteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendInvite(id: string, iSendInviteRequest: ISendInviteRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).sendInvite(id, iSendInviteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public testEmail(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).testEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {IUserUpdateRequest} iUserUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(id: string, iUserUpdateRequest: IUserUpdateRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(id, iUserUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public validatePasswordRecoveryToken(token: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).validatePasswordRecoveryToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}


