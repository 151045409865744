import { Button, Divider, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { ReactElement, ReactNode } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/const/routes";
import { useTranslation } from "react-i18next";
import { Header } from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import { useApi } from "../../../context/useApi";
import { useMessage } from "../../../context/useMessage";
import { PAGE_SIZE_LIST } from "../../../utils/const/conf";
import { getErrorResponse } from "../../../utils/helpers/errors";
import DownloadIcon from "@mui/icons-material/Download";
import { SearchFnReturn, useBaseList } from "../../../hooks/useBaseList";
import { IDiagnosticAppVersionResponse } from "../../../generated/axios";

export const ReleaseList = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { appVersionApi } = useApi();

  const searchFn = async (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ): Promise<SearchFnReturn<IDiagnosticAppVersionResponse>> => {
    const resp = await appVersionApi.searchAppVersions({
      filters: filters,
      start: start,
      size: size,
      sort: sort,
    });
    if (resp.status === 200) {
      return { total: resp.data.total ?? 0, list: resp.data.data ?? [] };
    } else {
      const err = t(`errors.${getErrorResponse(resp).code}`);
      error({ title: err });
      throw Error(err);
    }
  };

  const {
    list,
    loading,
    page,
    pageSize,
    total,
    sortModel,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    search,
  } = useBaseList<IDiagnosticAppVersionResponse>({ searchFn });

  const { error } = useMessage();

  const renderButtons = (params: any): ReactNode => {
    const onDownloadClick = (e: any) => {
      e.stopPropagation();

      appVersionApi.downloadAppVersionUrl(params.row.id).then((resp) => {
        if (resp.status === 200) {
          window.open(resp.data);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      });
    };

    const deprecateVersion = (id: string) => {
      appVersionApi.deprecateAppVersion(id).then((resp) => {
        if (resp.status === 200) {
          search();
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      });
    };

    const onDeprecateClick = (e: any) => {
      e.stopPropagation();
      deprecateVersion(params.row.id);
    };

    return (
      <Stack direction={"row"} spacing={1}>
        <IconButton
          title={t("actions.download")}
          size="small"
          onClick={onDownloadClick}
          color="primary"
        >
          <DownloadIcon />
        </IconButton>
        {!params.row.deprecated && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={onDeprecateClick}
          >
            {t("actions.deprecate")}
          </Button>
        )}
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: t("release.versionName"), flex: 1 },
    { field: "code", headerName: t("release.versionCode"), flex: 1 },
    { field: "changelog", headerName: t("release.changeLog"), flex: 1 },
    {
      field: "deprecated",
      headerName: t("release.deprecated"),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.deprecated ? "SI" : "NO",
    },
    {
      field: "type",
      headerName: t("release.type"),
      flex: 1,
      valueGetter: (params: any) =>
        params.row.type ? t(`enum.appVersionType.${params.row.type}`) : "",
    },
    {
      field: "file",
      headerName: "",
      sortable: false,
      flex: 1,
      renderCell: renderButtons,
      align: "center",
    },
  ];

  return (
    <>
      <Header>
        <Stack
          spacing={3}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb
            options={[{ title: t("release.title"), current: true }]}
          />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.release);
            }}
          >
            {t("actions.update")}
          </Button>
        </Stack>
      </Header>
      <div>
        <DataGrid
          sx={{ height: "calc(100vh - 350px)" }}
          loading={loading}
          disableColumnMenu
          columns={columns}
          rows={list}
          rowCount={total}
          page={page}
          onPageChange={onPageChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={PAGE_SIZE_LIST}
          pagination
          paginationMode="server"
          keepNonExistentRowsSelected
          sortModel={sortModel}
          onSortModelChange={onSortModelChanged}
        />
      </div>
    </>
  );
};
