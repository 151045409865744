import { Collapse, IconButton, Stack, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const PasswordCriteria = (): ReactElement => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack direction={"row"} alignItems="center">
        <Typography color={"primary"}>
          {open
            ? t("access.hidePasswordCriteria")
            : t("access.showPasswordCriteria")}
        </Typography>
        <IconButton
          color="primary"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {open ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Stack
          direction={"column"}
          spacing={1}
          sx={{
            mb: "10px",
            border: "2px solid",
            borderColor: "primary.main",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Typography color="secondary" fontSize={"11px"} fontWeight={600}>
            {t("errors.mustContainEightChar")}
          </Typography>
          <Typography color="secondary" fontSize={"11px"} fontWeight={600}>
            {t("errors.mustContainOneLower")}
          </Typography>
          <Typography color="secondary" fontSize={"11px"} fontWeight={600}>
            {t("errors.mustContainOneUpper")}
          </Typography>
          <Typography color="secondary" fontSize={"11px"} fontWeight={600}>
            {t("errors.mustContainOneDigit")}
          </Typography>
          <Typography color="secondary" fontSize={"11px"} fontWeight={600}>
            {t("errors.mustContainOneSpecial")}
          </Typography>
        </Stack>
      </Collapse>
    </>
  );
};
