import {
  TextField,
  MenuItem,
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
} from "@mui/material";
import { forwardRef, ReactElement, Ref } from "react";
import { lang } from "../../../utils/const/lang";
import { useTranslation } from "react-i18next";

interface StandardProps extends StandardTextFieldProps {
  useServerValue?: boolean;
}

interface FilledProps extends FilledTextFieldProps {
  useServerValue?: boolean;
}

interface OutlinedProps extends OutlinedTextFieldProps {
  useServerValue?: boolean;
}

type LangProps = StandardProps | FilledProps | OutlinedProps;

function LangComponent(
  { useServerValue, ...rest }: LangProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const { t } = useTranslation();

  return (
    <TextField
      ref={ref}
      {...rest}
      select // tell TextField to render select
    >
      {lang.map((el) => (
        <MenuItem value={useServerValue ? el.serverValue : el.id} key={el.id}>
          <img
            src={el.img}
            width={20}
            alt={t(el.label)}
            style={{ marginRight: "20px" }}
          />
          {t(el.label)}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default forwardRef<HTMLDivElement, LangProps>(LangComponent);
