import styled from "@emotion/styled";
import { Box, Container, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import theme from "../../theme";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 3px solid ${theme.palette.primary.main};
  padding: 25px;
`;

export const RegistrationCompleted = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Wrapper>
        <img
          src={window.location.origin + "/img/askoll_ultra_logo.png"}
          alt="askoll_ultra_logo"
          style={{ width: "50%", marginTop: "30px", marginBottom: "30px" }}
        />
        <FormWrapper>
          <Typography
            fontWeight={600}
            fontSize={"50px"}
            color="secondary"
            sx={{ mb: "30px" }}
          >
            {t("access.registrationCompletedForm.title")}
          </Typography>

          <Typography
            fontWeight={600}
            fontSize={"20px"}
            color="secondary"
            sx={{ mb: "70px" }}
          >
            {t("access.registrationCompletedForm.subtitle")}
          </Typography>
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};
