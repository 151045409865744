import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterWrapper, FlexEndContainer } from "../../common/Common.styles";

interface KitFiltersProps {
  search: (filters: any) => void;
  loading: boolean;
}

export const KitFilters = ({
  search,
  loading,
}: KitFiltersProps): ReactElement => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<{
    name?: string;
    swReference?: string;
    cmReference?: string;
    askollUnitProductId?: string;
  }>({});

  const clearFilters = () => {
    setFilters({});
    search({});
  };

  const searchFn = (e?: any) => {
    if (e.keyCode) {
      if (e.keyCode === 13) {
        search(filters);
      }
    } else {
      search(filters);
    }
  };

  return (
    <FilterWrapper>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={t("kit.name")}
            size="small"
            value={filters?.name ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, name: e.target.value }));
            }}
            onKeyDown={searchFn}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={t("kit.firmware")}
            size="small"
            value={filters?.swReference ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, swReference: e.target.value }));
            }}
            onKeyDown={searchFn}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={t("kit.template")}
            size="small"
            value={filters?.cmReference ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, cmReference: e.target.value }));
            }}
            onKeyDown={searchFn}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={t("kit.product")}
            size="small"
            value={filters?.askollUnitProductId ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                askollUnitProductId: e.target.value,
              }));
            }}
            onKeyDown={searchFn}
          />
        </Grid>
        <Grid item xs={4}>
          <FlexEndContainer>
            <LoadingButton
              variant="contained"
              color="secondary"
              sx={{ marginRight: "10px" }}
              loading={loading}
              onClick={clearFilters}
            >
              {t("actions.clear")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={searchFn}
              loading={loading}
            >
              {t("actions.search")}
            </LoadingButton>
          </FlexEndContainer>
        </Grid>
      </Grid>
    </FilterWrapper>
  );
};
