import { useCallback, useEffect, useState } from "react";
import { useApi } from "../context/useApi";
import { PAGE_SIZE_LIST } from "../utils/const/conf";
import { GridSortModel } from "@mui/x-data-grid";
import { getApiSort } from "../utils/helpers/sort";

export interface SearchFnReturn<T> {
  list: T[];
  total: number;
}

export interface BaseListProps<T> {
  searchFn: (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ) => Promise<SearchFnReturn<T>>;
}

export const useBaseList = <T>({ searchFn }: BaseListProps<T>) => {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(PAGE_SIZE_LIST[0]);

  const [total, setTotal] = useState(0);

  const [statefilters, setFilters] = useState<{ [key: string]: object }>({});

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const onSortModelChanged = (e: GridSortModel) => {
    setSortModel(e);
    setPage(0);
    search(0, undefined, undefined, e);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    search(newPage * pageSize, pageSize, undefined, undefined);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
    search(0, newPageSize, undefined, undefined);
  };

  const onFilterChange = (newFilters: { [key: string]: object }) => {
    setFilters(newFilters);
    setPage(0);
    search(0, undefined, newFilters, undefined);
  };

  const { userApi } = useApi();

  const [loading, setLoading] = useState(false);

  const [list, setList] = useState<T[]>([]);

  const search = useCallback(
    (
      start: number = page * pageSize,
      size: number = pageSize,
      filters: { [key: string]: object } = statefilters,
      sort: GridSortModel = sortModel
    ) => {
      setLoading(true);

      searchFn(start, size, filters, getApiSort(sort))
        .then((resp) => {
          setList(resp.list);
          setTotal(resp.total);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, statefilters, userApi, sortModel]
  );

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    list,
    sortModel,
    page,
    pageSize,
    total,
    statefilters,
    loading,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    search,
  };
};
