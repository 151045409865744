import { Button, Divider, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ReactElement, ReactNode } from "react";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/const/routes";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../common/Breadcrumb";
import { Header } from "../../common/Common.styles";
import { useApi } from "../../../context/useApi";
import { useMessage } from "../../../context/useMessage";
import { PAGE_SIZE_LIST } from "../../../utils/const/conf";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { getErrorResponse } from "../../../utils/helpers/errors";
import { SearchFnReturn, useBaseList } from "../../../hooks/useBaseList";
import { ISWResponse } from "../../../generated/axios";
import { useDialog } from "../../../context/useDialog";

export const FirmwareList = (): ReactElement => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { firmwareApi } = useApi();

  const searchFn = async (
    start: number,
    size: number,
    filters: { [key: string]: object },
    sort?: string[]
  ): Promise<SearchFnReturn<ISWResponse>> => {
    const resp = await firmwareApi.searchSws({
      filters: filters,
      start: start,
      size: size,
      sort: sort,
    });
    if (resp.status === 200) {
      return { total: resp.data.total ?? 0, list: resp.data.data ?? [] };
    } else {
      const err = t(`errors.${getErrorResponse(resp).code}`);
      error({ title: err });
      throw Error(err);
    }
  };

  const {
    list,
    loading,
    page,
    pageSize,
    total,
    sortModel,
    onSortModelChanged,
    onPageChange,
    onPageSizeChange,
    search,
  } = useBaseList<ISWResponse>({ searchFn });

  const { error, success } = useMessage();

  const { createSimpleConfirm } = useDialog();

  const deleteItem = (id: string) => {
    createSimpleConfirm({
      title: t("info.warning"),
      message: t("info.sureToDeleteFirmware"),
      onConfirm: () => {
        firmwareApi.deleteSw(id).then((resp) => {
          if (resp.status === 200) {
            success({ message: t("info.deleted") });
            search();
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          }
        });
      },
    });
  };

  const renderDownloadButton = (params: any): ReactNode => {
    const onDownload = (e: any) => {
      e.stopPropagation();
      firmwareApi.downloadSwUrl(params.row.id).then((resp) => {
        if (resp.status === 200) {
          window.open(resp.data);
        } else {
          error({ title: t(`errors.${getErrorResponse(resp).code}`) });
        }
      });
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      deleteItem(params.row.id);
    };

    return (
      <Stack spacing={10} direction="row" alignItems="center">
        <Button startIcon={<DownloadIcon />} onClick={onDownload}>
          {t("actions.download")}
        </Button>
        <IconButton onClick={onDelete} title={t("actions.delete")}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    { field: "sw", headerName: t("firmware.id"), flex: 1 },
    { field: "version", headerName: t("firmware.version"), flex: 1 },
    {
      field: "file",
      headerName: "",
      sortable: false,
      flex: 1,
      renderCell: renderDownloadButton,
      align: "center",
    },
  ];

  return (
    <>
      <Header>
        <Stack
          spacing={3}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb
            options={[{ title: t("firmware.title"), current: true }]}
          />
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              navigate(ROUTES.firmware);
            }}
          >
            {t("actions.update")}
          </Button>
        </Stack>
      </Header>
      <DataGrid
        sx={{ height: "calc(100vh - 200px)" }}
        loading={loading}
        disableColumnMenu
        columns={columns}
        rows={list}
        rowCount={total}
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={PAGE_SIZE_LIST}
        pagination
        paginationMode="server"
        keepNonExistentRowsSelected
        sortModel={sortModel}
        onSortModelChange={onSortModelChanged}
      />
    </>
  );
};
