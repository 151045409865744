export const ROUTES = {
  home: "/",
  login: "/login",
  user: "/user",
  users: "/users",
  changePassword: "/change-password",
  sendPasswordRecovery: "/send-password-recovery",
  passwordRecovery: "/password-recovery",
  passwordRegistration: "/password-registration",
  manufacturers: "/manufacturers",
  manufacturer: "/manufacturer",
  kit: "/kit",
  kits: "/kits",
  firmwares: "/firmwares",
  firmware: "/firmware",
  templates: "/templates",
  template: "/template",
  releases: "/releases",
  release: "/release",
} as const;
