import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  IBaseResponseUserRoleDataEnum,
  IUserResponse,
  IUserResponseEnabledProductsEnum,
  IUserResponseRolesEnum,
} from "../../../generated/axios";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { useUserDetail } from "../../../hooks/useUserDetail";
import { ROUTES } from "../../../utils/const/routes";
import { Breadcrumb } from "../../common/Breadcrumb";
import { FlexEndContainer, Header } from "../../common/Common.styles";
import { ManufacturerAutocomplete } from "../../common/ManufacturerAutocomplete";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../context/useAuth";
import { useApi } from "../../../context/useApi";
import { Lang } from "../../common/base";
import { lang } from "../../../utils/const/lang";

import SendIcon from "@mui/icons-material/Send";

const defaultValues: Omit<IUserResponse, "id"> = {
  email: "",
  name: "",
  roles: [],
  enabledProducts: "MIDMOTOR",
  emailTextLang: "IT",
};

export const UserDetail = (): ReactElement => {
  const { id } = useParams();

  const { t } = useTranslation();

  const {
    detail,
    loading,
    saveLoading,
    errors,
    save,
    handleCancel,
    handleDelete,
    sendInvite,
  } = useUserDetail(id);

  const { loggedUser } = useAuth();

  const { bindField, bindError, submit, form, setValue, setErrors } =
    useBaseForm({
      defaultValues: defaultValues,
      onSubmit: save,
      values: detail,
    });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const { userApi } = useApi();

  const getBreadcrumbName = () => {
    if (id || form.name) {
      return form.name;
    } else {
      return t("actions.new");
    }
  };

  const [userRoles, setUserRoles] = useState<IBaseResponseUserRoleDataEnum[]>();

  useEffect(() => {
    userApi.getUserRoles().then((resp) => {
      if (resp.status === 200) {
        setUserRoles(resp.data.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header>
        <Stack
          spacing={4}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
        >
          <Breadcrumb
            options={[
              { title: t("user.title"), to: ROUTES.users },
              {
                title: loading ? <CircularProgress /> : getBreadcrumbName(),
                current: true,
              },
            ]}
          />
          {detail?.id &&
            detail.emailTextLang &&
            lang
              .filter((el) => el.serverValue === detail.emailTextLang)
              .map((el) => (
                <img
                  src={el.img}
                  width={40}
                  alt={t(el.label)}
                  style={{ borderRadius: "5px" }}
                />
              ))}
          {detail?.id && (
            <Button
              color="secondary"
              startIcon={<DeleteIcon />}
              variant="contained"
              onClick={handleDelete}
            >
              {t("actions.delete")}
            </Button>
          )}
          {detail?.activationStatus === "WAITING" && (
            <Button
              endIcon={<SendIcon />}
              variant="contained"
              onClick={sendInvite}
            >
              {t("actions.sendInvite")}
            </Button>
          )}
        </Stack>
      </Header>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label={t("user.name")}
              {...bindField("name")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              autoComplete="off"
              label={t("user.email")}
              {...bindField("email")}
              disabled={detail?.id !== undefined}
            />
          </Grid>
          {id === undefined && (
            <Grid item xs={4}>
              <Lang
                useServerValue
                label={t("user.lang")}
                fullWidth
                {...bindField("emailTextLang")}
              />
            </Grid>
          )}
          {id !== undefined &&
            loggedUser?.permissions?.includes("USER_INSERTED_BY") && (
              <Grid item xs={4}>
                <TextField
                  autoComplete="off"
                  fullWidth
                  label={t("user.insertedFrom")}
                  {...bindField("insertedFrom")}
                />
              </Grid>
            )}
          <Grid item xs={4}>
            <TextField
              fullWidth
              select // tell TextField to render select
              label={t("user.enabledProducts")}
              SelectProps={{
                ...bindField("enabledProducts", undefined, undefined, []),
              }}
              {...bindError("enabledProducts")}
            >
              {Object.values(IUserResponseEnabledProductsEnum).map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select // tell TextField to render select
              label={t("user.role")}
              SelectProps={{
                ...bindField("roles", ["0"]),
              }}
              {...bindError("roles")}
            >
              {userRoles &&
                userRoles.map((el) => (
                  <MenuItem key={el} value={el}>
                    {t("enum.userRole." + el)}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {form.roles.includes(IUserResponseRolesEnum.Manufacturer) && (
            <Grid item xs={4}>
              <ManufacturerAutocomplete
                label={t("user.productor")}
                value={form.manufacturer ?? ""}
                {...bindError("manufacturerId")}
                onChange={(newVal) => {
                  setValue("manufacturer", newVal);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FlexEndContainer>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                sx={{ marginRight: "10px" }}
              >
                {t("actions.cancel")}
              </Button>
              <LoadingButton
                variant="contained"
                loading={saveLoading}
                onClick={submit}
              >
                {t("actions.save")}
              </LoadingButton>
            </FlexEndContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
