import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { ROUTES } from "../../../utils/const/routes";
import { FileUploader } from "../../fileUpload/FileUploader";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { useFileUploader } from "../../../hooks/useFileUploader";
import { useDialog } from "../../../context/useDialog";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../common/Breadcrumb";
import {
  FileUploaderItemActionWrapper,
  FileUploaderItemWrapper,
  Header,
} from "../../common/Common.styles";
import { useReleaseDetail } from "../../../hooks/useReleaseDetail";
import { IDiagnosticAppVersionRequestTypeEnum } from "../../../generated/axios";

export const ReleaseDetail = (): ReactElement => {
  const { t } = useTranslation();

  const { detail, errors, save } = useReleaseDetail();

  const { files, addFiles, removeFile } = useFileUploader({ count: 1 });

  const { createSimpleConfirm } = useDialog();

  const { bindField, form, submit, setErrors, bindError } = useBaseForm({
    defaultValues: {},
    onSubmit: (el: any) => {
      createSimpleConfirm({
        title: t("info.warning"),
        message: t("info.sureToRleaseAppVersion"),
        onConfirm: () => {
          save(el, files[0]);
        },
      });
    },
    values: detail,
  });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const getBreadcrumbName = () => {
    if (form.versionName) {
      return form.versionName;
    } else {
      return t("release.new");
    }
  };

  return (
    <>
      <Header>
        <Breadcrumb
          options={[
            { title: t("release.title"), to: ROUTES.releases },
            { title: getBreadcrumbName(), current: true },
          ]}
        />
      </Header>
      <FileUploader onUpload={addFiles} count={1} />
      {files.map((el, index) => (
        <FileUploaderItemWrapper key={index}>
          <Typography>{el.name}</Typography>
          <TextField label={t("release.versionName")} {...bindField("name")} />
          <TextField label={t("release.versionCode")} {...bindField("code")} />
          <TextField
            label={t("release.changeLog")}
            {...bindField("changelog")}
          />
          <TextField
            select // tell TextField to render select
            label={t("release.type")}
            SelectProps={{
              ...bindField("type"),
            }}
            {...bindError("type")}
          >
            {Object.values(IDiagnosticAppVersionRequestTypeEnum).map((el) => (
              <MenuItem key={el} value={el}>
                {t("enum.appVersionType." + el)}
              </MenuItem>
            ))}
          </TextField>
          <FileUploaderItemActionWrapper>
            <Button onClick={submit}>
              <UploadIcon />
              {t("actions.upload")}
            </Button>
            <IconButton color="primary" onClick={() => removeFile(el.name)}>
              <CloseIcon />
            </IconButton>
          </FileUploaderItemActionWrapper>
        </FileUploaderItemWrapper>
      ))}
    </>
  );
};
