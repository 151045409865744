import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../context/useApi";
import { useMessage } from "../../context/useMessage";
import { IAskollKitResponse } from "../../generated/axios";
import {
  AUTOCOMPLETE_MIN_CHARACTERS_FOR_SEARCH,
  AUTOCOMPLETE_SEARCH_LIMIT,
} from "../../utils/const/conf";
import { getErrorResponse } from "../../utils/helpers/errors";

export interface KitAutocompleteProps {
  placeholder?: string;
  value?: IAskollKitResponse[];
  onChange: (value: IAskollKitResponse[]) => void;
  label: string;
  helperText?: string;
}

export const KitAutocomplete = ({
  placeholder,
  onChange,
  value,
  label,
  helperText,
}: KitAutocompleteProps): ReactElement => {
  const [options, setOptions] = useState<IAskollKitResponse[]>([]);

  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { error } = useMessage();

  const { kitApi } = useApi();

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length >= AUTOCOMPLETE_MIN_CHARACTERS_FOR_SEARCH) {
        setLoading(true);
        kitApi
          .searchAskollKit({
            //@ts-ignore
            filters: { name: inputValue },
            start: 0,
            size: AUTOCOMPLETE_SEARCH_LIMIT,
          })
          .then((resp) => {
            if (resp.status === 200 && resp.data) {
              setOptions(resp.data.data ?? []);
            } else {
              error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      // Send Axios request here
    }, 200);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitApi, inputValue]);

  const renderTagHandler = (
    val: IAskollKitResponse[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    return val.map((item, index: number) => (
      <Chip
        key={index}
        label={item.name}
        onDelete={getTagProps({ index }).onDelete}
        sx={{ marginRight: "5px" }}
      />
    ));
  };

  function isKitOption(value: any): value is IAskollKitResponse {
    return value.hasOwnProperty("name") || value.hasOwnProperty("askollKits");
  }

  const optionLabelHandler = (option: IAskollKitResponse) => {
    if (isKitOption(option)) {
      return option.name ?? (option.id as unknown as string) ?? "";
    } else {
      return "";
    }
  };

  const onChangeHandler = (_e: any, val: (string | IAskollKitResponse)[]) => {
    if (Array.isArray(val)) {
      onChange(
        val.map((item) => {
          if (isKitOption(item)) {
            return item;
          } else {
            throw Error("Errore nella tipizzazione");
          }
        })
      );
    } else {
      if (isKitOption(val)) {
        onChange(val);
      } else {
        // onChange({ name: val || undefined, askollKits: undefined });
      }
    }
  };

  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={inputValue}
      autoSelect
      multiple={true}
      fullWidth
      filterOptions={(x) => x}
      loading={loading}
      onInputChange={handleInputChange}
      renderTags={renderTagHandler}
      getOptionLabel={optionLabelHandler}
      onChange={onChangeHandler}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          label={label}
          helperText={helperText}
          error={helperText !== undefined}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...inputProps,
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};
