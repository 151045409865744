import { ReactElement, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Menu } from "./Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { MenuItem, Menu as MenuMUI } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/useAuth";
import { ROUTES } from "../utils/const/routes";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Lang } from "../components/common/base";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const BaseLayout = (): ReactElement => {
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  const handleToggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openAccountMenu = Boolean(anchorEl);

  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  const { logout } = useAuth();

  const changePassword = () => {
    navigate(ROUTES.changePassword);
    handleAccountClose();
  };

  const { t, i18n } = useTranslation();

  const { loggedUser } = useAuth();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            edge="start"
            sx={{
              marginRight: 5,
              // ...(open && { display: "none" }),
            }}
          >
            {open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Askoll Diagnostics
          </Typography>
          <Lang
            label={t("access.lang")}
            color="secondary"
            sx={{ mr: "20px" }}
            size="small"
            value={i18n.language}
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleAccountClick}
          >
            <AccountCircleIcon />
          </IconButton>
          <Typography>{loggedUser?.name}</Typography>
          <MenuMUI
            anchorEl={anchorEl}
            open={openAccountMenu}
            onClose={handleAccountClose}
          >
            <MenuItem onClick={changePassword}>
              <ListItemIcon>
                <VpnKeyIcon />
              </ListItemIcon>
              <ListItemText>{t("actions.changePassword")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{t("actions.logout")}</ListItemText>
            </MenuItem>
          </MenuMUI>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div
            style={{
              display: "flex",
              height: "40px",
            }}
          >
            <img
              src={window.location.origin + "/img/AskollUltraBlack.png"}
              alt="Askoll Ultra"
              style={{ width: "inherit" }}
            />
          </div>
          {/* <IconButton onClick={handleToggleDrawer} sx={{ ml: "auto" }}>
            {/* {theme.direction === "rtl" ? (
              <MenuOpenIcon />
            ) : (
              <ChevronLeftIcon />
            )} }
            <MenuOpenIcon />
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <Menu open={open} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
