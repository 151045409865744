import { GridSortModel } from "@mui/x-data-grid";

export const getApiSort = (s?: GridSortModel): string[] | undefined => {
  if (s) {
    return s.map((i) => `${i.sort === "desc" ? "-" : ""}${i.field}`);
  }
};

export const getClientSort = (s: string[]): GridSortModel | undefined => {
  if (s) {
    let model: GridSortModel = s.map((i) =>
      i.startsWith("-")
        ? { field: i.substring(1), sort: "desc" }
        : { field: i, sort: "asc" }
    );
    return model;
  }
};
