import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactElement, useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useMessage } from "../../context/useMessage";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

export interface FileUploaderProps {
  onUpload: (files: any[]) => void;
  count?: number;
  formats?: string[];
}

const UploadContainer = styled(Box)<{ dragging: boolean }>`
  border: 1px dotted;
  border-color: ${(props) =>
    props.dragging ? theme.palette.primary.light : "black"};
  color: ${(props) => (props.dragging ? theme.palette.primary.light : "black")};
  background-color: ${(props) => (props.dragging ? "white" : "white")};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  margin-bottom: 20px;
  :hover {
    border-color: ${theme.palette.primary.light};
    color: ${theme.palette.primary.light};
  }
`;

export const FileUploader = ({
  onUpload,
  count,
  formats,
}: FileUploaderProps): ReactElement => {
  const { error } = useMessage();

  const { t } = useTranslation();

  const [isDragging, setDragging] = useState(false);

  const drop = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let dropRef = drop.current;
    dropRef?.addEventListener("dragover", handleDragOver);
    dropRef?.addEventListener("drop", handleDrop);
    dropRef?.addEventListener("dragenter", handleDragEnter);
    dropRef?.addEventListener("dragleave", handleDragLeave);

    return () => {
      dropRef?.removeEventListener("dragover", handleDragOver);
      dropRef?.removeEventListener("drop", handleDrop);
      dropRef?.removeEventListener("dragenter", handleDragEnter);
      dropRef?.removeEventListener("dragleave", handleDragLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    setDragging(false);
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.dataTransfer.files];
    if (validateFiles(files)) {
      if (files && files.length) {
        onUpload(files);
      }
    }
  };

  const validateFiles = (files: any[]) => {
    // check if the provided count prop is less than uploaded count of files
    if (count && count < files.length) {
      error({
        title: t("errors.error"),
        message: t("errors.fileAtATime", { count: count }),
      });
      return;
    }

    // check if some uploaded file is not in one of the allowed formats
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      error({
        title: t("errors.error"),
        message: `${t("errors.onlyAllowedFormat")} ${formats.join(", ")}`,
      });
      return;
    }
    return true;
  };

  const onFileUploaderClick = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*");
    input.multiple = (count ?? 0) !== 1;

    input.addEventListener("change", (ev) => {
      let e: any = ev;
      const files = [...e.target.files];
      if (validateFiles(files)) {
        onUpload(files);
      }
    });

    input.click();
  };

  return (
    <UploadContainer
      ref={drop}
      dragging={isDragging}
      onClick={onFileUploaderClick}
    >
      {!isDragging && (
        <>
          <CloudUploadIcon fontSize="large" />
          <Typography>{t("info.dropHere")}</Typography>
        </>
      )}
    </UploadContainer>
  );
};
