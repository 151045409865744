import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApi } from "../context/useApi";
import { useMessage } from "../context/useMessage";
import { IAskollKitResponse } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";
import { getErrorResponse } from "../utils/helpers/errors";
import { FormError } from "./useBaseForm";

export const useKitDetail = (id?: string) => {
  const [loading, setLoading] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);

  const [errors, setErrors] = useState<FormError[]>([]);

  const navigate = useNavigate();

  const [detail, setDetail] = useState<IAskollKitResponse>();

  const { error, success } = useMessage();

  const { kitApi } = useApi();

  const { t } = useTranslation();

  const get = useCallback(
    (id: string) => {
      setLoading(true);
      kitApi
        .getAskollKit(id)
        .then((resp) => {
          if (resp.status === 200) {
            setDetail(resp.data);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
          }
        })
        .finally(() => {
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kitApi]
  );

  useEffect(() => {
    if (id) {
      get(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, get]);

  const save = (el: any) => {
    if (el.id) {
      setSaveLoading(true);
      kitApi
        .updateAskollKit(el.id, { ...el })
        .then((resp) => {
          if (resp.status === 200) {
            setDetail(resp.data);
            success({ message: t("info.saved") });
            setErrors([]);
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            setErrors(getErrorResponse(resp).errors ?? []);
          }
        })
        .finally(() => {
          setSaveLoading(false);
        });
    } else {
      setSaveLoading(true);
      kitApi
        .newAskollKit({ ...el })
        .then((resp) => {
          if (resp.status === 200) {
            success({ message: t("info.saved") });
            setErrors([]);
            navigate(resp.data.id + "");
          } else {
            error({ title: t(`errors.${getErrorResponse(resp).code}`) });
            setErrors(getErrorResponse(resp).errors ?? []);
          }
        })
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };

  const handleCancel = () => {
    if (!id) {
      navigate(ROUTES.kits);
    } else {
      get(id);
    }
  };

  return { detail, loading, saveLoading, errors, save, handleCancel };
};
