import { IUserResponseEmailTextLangEnum } from "../../generated/axios";

export interface Lang {
  id: string;
  img: string;
  label: string;
  serverValue: IUserResponseEmailTextLangEnum;
}

export const lang: Lang[] = [
  {
    id: "it",
    img: "https://flagcdn.com/it.svg",
    label: "access.langs.it",
    serverValue: "IT",
  },
  {
    id: "en",
    img: "https://flagcdn.com/gb.svg",
    label: "access.langs.en",
    serverValue: "EN",
  },
];
