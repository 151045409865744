import {
  TextFieldProps,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { forwardRef, ReactElement, Ref, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PassswordComponent(
  { ...rest }: TextFieldProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      ref={ref}
      {...rest}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default forwardRef<HTMLDivElement, TextFieldProps>(PassswordComponent);
