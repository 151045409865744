import styled from "@emotion/styled";
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../context/useApi";
import { useMessage } from "../../context/useMessage";
import { useBaseForm } from "../../hooks/useBaseForm";
import i18n from "../../i18n";
import theme from "../../theme";
import { ROUTES } from "../../utils/const/routes";
import { getErrorResponse } from "../../utils/helpers/errors";
import { Lang } from "../common/base";
import { SendPasswordRecoveryCompleted } from "./SendPasswordRecoveryCompleted";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 3px solid ${theme.palette.primary.main};
  padding: 25px;
`;

export const SendPasswordRecovery = (): ReactElement => {
  const { t } = useTranslation();

  const { form, bindField, setErrors } = useBaseForm({
    defaultValues: { email: "" },
    onSubmit: () => {},
  });

  const [searchParams] = useSearchParams();

  const app = useMemo(() => {
    return searchParams.has("app_user");
  }, [searchParams]);

  const [completed, setCompleted] = useState(false);

  const { userApi } = useApi();

  const navigate = useNavigate();

  const { error } = useMessage();

  const send = () => {
    setErrors([]);
    userApi.resetPasswordRequest({ email: form.email }).then((resp) => {
      if (resp.status === 200) {
        setCompleted(true);
      } else {
        setErrors(
          getErrorResponse(resp).errors?.map((el) => ({
            field: el.field,
            message: el.code,
          })) ?? []
        );
        error({ title: t(`errors.${getErrorResponse(resp).code}`) });
      }
    });
  };

  if (completed) {
    return <SendPasswordRecoveryCompleted />;
  }

  return (
    <Container maxWidth="md">
      <Wrapper>
        <img
          src={window.location.origin + "/img/askoll_ultra_logo.png"}
          alt="askoll_ultra_logo"
          style={{ width: "50%", marginTop: "30px", marginBottom: "30px" }}
        />
        <FormWrapper>
          <Typography fontWeight={600} fontSize={"50px"} color="secondary">
            {t("access.sendPasswordRecoveryForm.title")}
          </Typography>

          <Typography
            fontWeight={600}
            fontSize={"20px"}
            color="secondary"
            sx={{ mb: "70px" }}
          >
            {t("access.sendPasswordRecoveryForm.subtitle")}
          </Typography>

          <TextField
            sx={{ mb: "40px", width: "500px" }}
            label={t("access.email")}
            fullWidth
            {...bindField("email")}
          />

          <Lang
            label={t("access.lang")}
            sx={{ mb: "40px", width: "300px" }}
            size="small"
            fullWidth
            value={i18n.language}
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
          />

          <Stack
            justifyContent="flex-end"
            sx={{ width: "100%" }}
            flexDirection="row"
          >
            <Button
              variant="contained"
              size="large"
              disabled={form.email ? false : true}
              onClick={send}
            >
              {t("actions.send")}
            </Button>
          </Stack>

          {!app && (
            <Typography fontSize={"13px"}>
              <strong>
                <Link
                  onClick={() => {
                    navigate(ROUTES.login);
                  }}
                >
                  {t("access.backToLogin")}
                </Link>
              </strong>
            </Typography>
          )}
        </FormWrapper>
      </Wrapper>
    </Container>
  );
};
