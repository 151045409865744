import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/useAuth";
import { IAuthRequest } from "../../generated/axios";
import { FlexColumn } from "../common/Common.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/const/routes";
import { Lang, Password } from "../common/base";

const LoginWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`;

const LogingContainer = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
`;

export const Login = (): ReactElement => {
  const { i18n, t } = useTranslation();

  const [form, setForm] = useState<IAuthRequest>({ email: "", password: "" });

  const { login } = useAuth();

  const handleLogin = () => {
    login(form?.email, form?.password);
  };

  const navigate = useNavigate();

  return (
    <LogingContainer>
      <FlexColumn style={{ alignItems: "center", width: "50vw" }}>
        <img
          src={window.location.origin + "/img/askoll_ultra_logo.png"}
          alt="askoll_ultra_logo"
          style={{ width: "50%", marginBottom: "70px" }}
        />
        <img
          src={window.location.origin + "/img/emtb_drive_unit_askollultra.png"}
          alt="emtb_drive_unit_askollultra"
          style={{ width: "inherit" }}
        />
      </FlexColumn>
      <LoginWrapper>
        <Typography fontWeight={600} fontSize={"50px"} color="secondary">
          {t("access.loginTitle")}
        </Typography>

        <Typography
          fontWeight={600}
          fontSize={"20px"}
          color="secondary"
          sx={{ mb: "30px" }}
        >
          {t("access.loginSubtitle")}
        </Typography>
        <TextField
          sx={{ mb: "20px", width: "500px" }}
          label={t("access.userName")}
          fullWidth
          value={form?.email}
          onChange={(e) => {
            setForm((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
        <Password
          sx={{ mb: "30px", width: "500px" }}
          label={t("access.password")}
          fullWidth
          value={form?.password}
          onChange={(e) => {
            setForm((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        <Lang
          label={t("access.lang")}
          sx={{ mb: "40px", width: "300px" }}
          size="small"
          fullWidth
          value={i18n.language}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
          }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleLogin}
          sx={{ marginBottom: "40px" }}
        >
          {t("actions.login")}
          <LoginIcon style={{ marginLeft: "20px" }} />
        </Button>

        <Typography fontSize={"16px"} sx={{ marginBottom: "20px" }}>
          {t("access.forgotPassword")}{" "}
          <strong>
            <Link
              onClick={() => {
                navigate(ROUTES.sendPasswordRecovery);
              }}
            >
              {t("actions.clickHere")}
            </Link>
          </strong>
        </Typography>
        <Typography fontSize={"13px"}>
          {t("access.loginHelp")}
          <strong>
            <Link href="mailto:info@askoll.com">info@askoll.com</Link>
          </strong>
        </Typography>
      </LoginWrapper>
    </LogingContainer>
  );
};
