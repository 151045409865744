import { IconButton, TextField, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { ROUTES } from "../../../utils/const/routes";
import { FileUploader } from "../../fileUpload/FileUploader";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { useFileUploader } from "../../../hooks/useFileUploader";
import { useDialog } from "../../../context/useDialog";
import { useTranslation } from "react-i18next";
import {
  FileUploaderItemActionWrapper,
  FileUploaderItemWrapper,
  Header,
} from "../../common/Common.styles";
import { Breadcrumb } from "../../common/Breadcrumb";
import { useFirmwareDetail } from "../../../hooks/useFirmwareDetail";
import { ISWResponse } from "../../../generated/axios";
import { LoadingButton } from "@mui/lab";

const defaultValues: Omit<ISWResponse, "id"> = {
  sw: "",
  version: "",
};

export const FirmwareDetail = (): ReactElement => {
  const { id } = useParams();

  const { detail, errors, save, saveLoading } = useFirmwareDetail(id);

  const { t } = useTranslation();

  const { files, addFiles, removeFile } = useFileUploader({ count: 1 });

  const { createSimpleConfirm } = useDialog();

  const { bindField, submit, setErrors } = useBaseForm({
    defaultValues: defaultValues,
    onSubmit: (el: any) => {
      createSimpleConfirm({
        title: t("info.warning"),
        message: t("info.sureToUpdateThisVersion"),
        onConfirm: () => {
          save(el, files[0]);
        },
      });
    },
    values: detail,
  });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const getBreadcrumbName = () => {
    return t("actions.new");
  };

  return (
    <>
      <Header>
        <Breadcrumb
          options={[
            { title: t("firmware.title"), to: ROUTES.firmwares },
            { title: getBreadcrumbName(), current: true },
          ]}
        />
      </Header>
      <FileUploader onUpload={addFiles} count={1} />
      {files.map((el, index) => (
        <FileUploaderItemWrapper key={index}>
          <Typography>{el.name}</Typography>
          <TextField
            label={t("firmware.id")}
            {...bindField("sw")}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <TextField
            label={t("firmware.version")}
            {...bindField("version")}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />
          <FileUploaderItemActionWrapper>
            <LoadingButton onClick={submit} loading={saveLoading}>
              <UploadIcon />
              {t("actions.upload")}
            </LoadingButton>
            <IconButton color="primary" onClick={() => removeFile(el.name)}>
              <CloseIcon />
            </IconButton>
          </FileUploaderItemActionWrapper>
        </FileUploaderItemWrapper>
      ))}
    </>
  );
};
