import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AccessControllerApi,
  AskollKitControllerApi,
  AskollUnitControllerApi,
  CmControllerApi,
  Configuration,
  DiagnosticAppVersionControllerApi,
  ManufacturerControllerApi,
  SwControllerApi,
  UsersApi,
} from "../generated/axios";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import { AUTH_TOKEN } from "./useAuth";

const axiosInstance: AxiosInstance = axios.create();

export interface BaseApiParams {
  configuration?: Configuration;
  basePath: undefined;
  axiosInstance: AxiosInstance;
}

interface ApiContextType {
  conf: BaseApiParams;
  userApi: UsersApi;
  manufacturerApi: ManufacturerControllerApi;
  accessApi: AccessControllerApi;
  kitApi: AskollKitControllerApi;
  firmwareApi: SwControllerApi;
  appVersionApi: DiagnosticAppVersionControllerApi;
  templateApi: CmControllerApi;
  kitUnitApi: AskollUnitControllerApi;
}

const ApiContext = createContext<ApiContextType>({} as ApiContextType);

function getCookie(name = AUTH_TOKEN) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  //@ts-ignore
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function ApiContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [cookies, , removeCookie] = useCookies();

  const [token, setToken] = useState<string>();

  let auth = cookies[AUTH_TOKEN];

  useEffect(() => {
    if (auth) {
      setToken(auth);
    }
  }, [auth]);

  //INTERCEPTOR RESPONSE OK
  const handleResponse = (res: AxiosResponse) => {
    return res;
  };

  //INTERCEPTOR RESPONSE KO
  const handleError = (error: any) => {
    if (error.response.status === 401) {
      removeCookie(AUTH_TOKEN);
    } else if (error.response.status === 404) {
      // window.location.pathname = "404";
      return error.response;
    } else {
      return error.response;
    }
  };

  //INTERCEPTOR REQUEST OK
  const handleRequest = (conf: AxiosRequestConfig) => {
    let temp = conf.headers;
    if (temp) {
      if (!temp["AUTH_TOKEN"]) {
        temp["X-ACCESS-TOKEN"] = getCookie() as string;
      }
      // temp["X-ACCESS-TOKEN"] = getCookie() as string;
      // console.log("TOKEN", getCookie());
    }
    conf.headers = temp;

    conf.url = conf.url?.replaceAll("pageable.", "");
    return conf;
  };

  axiosInstance.interceptors.response.use(handleResponse, handleError);

  axiosInstance.interceptors.request.use(handleRequest);

  const cachedValue: ApiContextType = useMemo(
    () => ({
      conf: {
        configuration: new Configuration({ apiKey: token }),
        basePath: undefined,
        axiosInstance: axiosInstance,
      },
      userApi: new UsersApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      manufacturerApi: new ManufacturerControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      accessApi: new AccessControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      kitApi: new AskollKitControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      firmwareApi: new SwControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      appVersionApi: new DiagnosticAppVersionControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      templateApi: new CmControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
      kitUnitApi: new AskollUnitControllerApi(
        new Configuration({ apiKey: token }),
        undefined,
        axiosInstance
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  return (
    <ApiContext.Provider value={cachedValue}>{children}</ApiContext.Provider>
  );
}

//hook per utilizzo webSocket
export const useApi = () => useContext(ApiContext);
