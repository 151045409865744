import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/useAuth";
import { IUserResponsePermissionsEnum } from "../generated/axios";
import { ROUTES } from "../utils/const/routes";

export const PermissionRedirect = () => {
  const { loggedUser } = useAuth();

  if (loggedUser) {
    if (loggedUser?.permissions?.includes("USER")) {
      return <Navigate to={ROUTES.users} />;
    }
    if (loggedUser?.permissions?.includes("MANUFACTURER")) {
      return <Navigate to={ROUTES.manufacturers} />;
    }
    if (loggedUser?.permissions?.includes("ASKOLL_KIT")) {
      return <Navigate to={ROUTES.kits} />;
    }
    if (loggedUser?.permissions?.includes("FIRMWARE")) {
      return <Navigate to={ROUTES.firmwares} />;
    }
    if (loggedUser?.permissions?.includes("TEMPLATE")) {
      return <Navigate to={ROUTES.templates} />;
    }
    if (loggedUser?.permissions?.includes("APP_VERSION")) {
      return <Navigate to={ROUTES.releases} />;
    }
    return <Typography>No permission found</Typography>;
  } else {
    return <Navigate to={ROUTES.login} />;
  }
};

interface ProtectedRouteProps {
  permissions: IUserResponsePermissionsEnum[];
  children: ReactElement;
}

export const ProtectedRoute = ({
  permissions,
  children,
}: ProtectedRouteProps): ReactElement | null => {
  const { loggedUser, token } = useAuth();

  let allow = token !== undefined;

  if (loggedUser !== undefined) {
    permissions.forEach((el) => {
      if (allow) {
        allow = (allow && loggedUser?.permissions?.includes(el)) ?? false;
      }
    });
  }

  if (allow) {
    return children;
  } else {
    return <PermissionRedirect />;
  }
};
