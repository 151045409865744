import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IAskollKitResponse } from "../../../generated/axios";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { useKitDetail } from "../../../hooks/useKitDetail";
import { ROUTES } from "../../../utils/const/routes";
import { Breadcrumb } from "../../common/Breadcrumb";
import { FlexEndContainer, Header } from "../../common/Common.styles";
import { FirmwareAutocomplete } from "../../common/FirmwareAutocomplete";
import { ProductAutocomplete } from "../../common/ProductAutocomplete";
import { TemplateAutocomplete } from "../../common/TemplateAutocomplete";

const defaultValues: Omit<IAskollKitResponse, "id"> = {
  name: "",
  hasAskollBattery: false,
  cmReference: "",
  swReference: "",
  askollUnitProductId: "",
};

export const KitDetail = (): ReactElement => {
  const { id } = useParams();

  const { detail, loading, errors, save, handleCancel } = useKitDetail(id);

  const { t } = useTranslation();

  const { bindField, bindError, submit, form, setValue, setErrors } =
    useBaseForm({
      defaultValues: defaultValues,
      onSubmit: save,
      values: detail,
    });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const getBreadcrumbName = () => {
    if (id || form.name) {
      return form.name;
    } else {
      return t("actions.new");
    }
  };

  return (
    <>
      <Header>
        <Breadcrumb
          options={[
            { title: t("kit.title"), to: ROUTES.kits },
            {
              title: loading ? <CircularProgress /> : getBreadcrumbName(),
              current: true,
            },
          ]}
        />
      </Header>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <TextField fullWidth label={t("kit.name")} {...bindField("name")} />
          </Grid>
          <Grid item xs={4}>
            <TemplateAutocomplete
              label={t("kit.template")}
              value={form.cmReference ?? ""}
              onChange={(newVal) => {
                setValue("cmReference", newVal);
              }}
              {...bindError("cmReference")}
            />
          </Grid>
          <Grid item xs={4}>
            <ProductAutocomplete
              label={t("kit.product")}
              value={form.askollUnitProductId ?? ""}
              onChange={(newVal) => {
                setValue("askollUnitProductId", newVal);
              }}
              {...bindError("cmReference")}
            />
          </Grid>
          <Grid item xs={4}>
            <FirmwareAutocomplete
              label={t("kit.firmware")}
              value={form.swReference ?? ""}
              onChange={(newVal) => {
                setValue("swReference", newVal);
              }}
              {...bindError("cmReference")}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  {...bindField("hasAskollBattery", undefined, "checked")}
                />
              }
              label={t("kit.includedBattery")}
            />
          </Grid>
          <Grid item xs={12}>
            <FlexEndContainer>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                sx={{ marginRight: "10px" }}
              >
                {t("actions.cancel")}
              </Button>
              <Button variant="contained" onClick={submit}>
                {t("actions.save")}
              </Button>
            </FlexEndContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
