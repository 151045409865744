export interface ErrorResponse {
  code: string;
  errors?: [{ field: string; code: string }];
}

export const getErrorResponse = (resp: any): ErrorResponse => {
  if (resp.status === 401) {
    return { code: resp.code };
  } else if (resp.status === 403) {
    return { code: resp.code };
  } else {
    return { code: resp.data.code, errors: resp.data.errors };
  }
};
