import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IManufacturerResponse } from "../../../generated/axios";
import { useBaseForm } from "../../../hooks/useBaseForm";
import { useManufacturerDetail } from "../../../hooks/useManufacturerDetail";
import { ROUTES } from "../../../utils/const/routes";
import { Breadcrumb } from "../../common/Breadcrumb";
import { FlexEndContainer, Header } from "../../common/Common.styles";
import { KitAutocomplete } from "../../common/KitAutocomplete";

const defaultValues: Omit<IManufacturerResponse, "id"> = {
  name: "",
  askollKits: [],
};

export const ManufacturerDetail = (): ReactElement => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { detail, errors, handleCancel, loading, save } =
    useManufacturerDetail(id);

  const { bindField, bindError, submit, form, setValue, setErrors } =
    useBaseForm({
      defaultValues: defaultValues,
      onSubmit: save,
      values: detail,
    });

  useEffect(() => {
    setErrors(errors.map((el) => ({ field: el.field, message: el.code })));
  }, [errors, setErrors]);

  const getBreadcrumbName = () => {
    if (id || form.name) {
      return form.name;
    } else {
      return t("actions.new");
    }
  };

  return (
    <>
      <Header>
        <Breadcrumb
          options={[
            { title: t("productor.title"), to: ROUTES.manufacturers },
            {
              title: loading ? <CircularProgress /> : getBreadcrumbName(),
              current: true,
            },
          ]}
        />
      </Header>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label={t("productor.name")}
              {...bindField("name")}
            />
          </Grid>
          <Grid item xs={8}>
            <KitAutocomplete
              label={t("productor.kit")}
              value={form.askollKits ?? []}
              onChange={(newVal) => {
                setValue("askollKits", newVal);
              }}
              {...bindError("askollKits")}
            />
          </Grid>
          <Grid item xs={12}>
            <FlexEndContainer>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="secondary"
                sx={{ marginRight: "10px" }}
              >
                {t("actions.cancel")}
              </Button>
              <Button variant="contained" onClick={submit}>
                {t("actions.save")}
              </Button>
            </FlexEndContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
