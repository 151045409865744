export const AT_LEAST_ONE_NUMBER = /\d/;

export const AT_LEAST_ONE_SPECIAL_CHAR = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/;

export const AT_LEAST_ONE_UPPER = /[A-Z]/;

export const AT_LEAST_ONE_LOWER = /[a-z]/;

export const AT_LEAST_EIGHT_CHAR = /.{8,}/;

export const NO_WHITE_SPACES = /(?!.* )/;

/**
 *
 * @param pwd
 * @returns the i18n label if the password does not match the criteria `or` undefined if the password is ok
 */
export const getPasswordErrorLabel = (pwd: string) => {
  if (pwd.indexOf(" ") !== -1) {
    return "mustContainNoWhiteSpaces";
  }
  if (!AT_LEAST_EIGHT_CHAR.test(pwd)) {
    return "mustContainEightChar";
  }
  if (!AT_LEAST_ONE_LOWER.test(pwd)) {
    return "mustContainOneLower";
  }
  if (!AT_LEAST_ONE_UPPER.test(pwd)) {
    return "mustContainOneUpper";
  }
  if (!AT_LEAST_ONE_NUMBER.test(pwd)) {
    return "mustContainOneDigit";
  }
  if (!AT_LEAST_ONE_SPECIAL_CHAR.test(pwd)) {
    return "mustContainOneSpecial";
  }
};
